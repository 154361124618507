import Leaflet from 'leaflet';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

export const DevicesMapControl = ({ position = 'topright', children }) => {
  const [container, setContainer] = useState(document.createElement('div'));
  const positionClass = POSITION_CLASSES[position];

  useEffect(() => {
    const targetDiv = document.getElementsByClassName(positionClass);
    if (targetDiv) {
      setContainer(targetDiv[0]);
    }
  }, []);

  const controlContainer = <div className="leaflet-control leaflet-bar">{children}</div>;

  Leaflet.DomEvent.disableClickPropagation(container);

  return ReactDOM.createPortal(controlContainer, container);
};

DevicesMapControl.propTypes = {
  position: PropTypes.string,
};
