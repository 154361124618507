import { useCallback } from 'react';
import { useStateContext } from 'store';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import {
  ModalAdminsAdd,
  ModalAdminsEdit,
  ModalAdminsChangePassword,
  ModalAdminsRecovery,
} from 'containers/UI/Modal/Components/Admins';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as PasswordChange } from 'icons/passwordChange.svg';
import { ReactComponent as Lock } from 'icons/lock.svg';
import { ReactComponent as PadLock } from 'icons/padlock.svg';
import { ReactComponent as Recovery } from 'icons/recovery.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useAdmins = () => {
  const { openPopup, admins, isAdminsLoading, totalAdminsCount, blockUnblockAdmin, closePopup } = useStateContext();

  const handleCreate = useCallback(() => {
    openPopup(() => <ModalAdminsAdd />, {
      title: 'Создание администратора',
    });
  }, []);

  const handleChangePassword = useCallback(admin => {
    openPopup(() => <ModalAdminsChangePassword adminData={admin} />, {
      title: 'Смена пароля',
    });
  }, []);

  const handleEdit = useCallback(admin => {
    openPopup(() => <ModalAdminsEdit adminData={admin} />, {
      title: 'Редактирование администратора',
    });
  }, []);

  const handleRecovery = useCallback(admin => {
    openPopup(() => <ModalAdminsRecovery adminData={admin} />, {
      title: 'Восстановление администратора',
    });
  }, []);

  const toggleBlocked = useCallback(admin => {
    blockUnblockAdmin({ id: admin.id, isBlocked: !admin.isBlocked }, closePopup);
  }, []);

  const header = [
    { key: 'email', title: 'Email' },
    { key: 'countDevices', title: 'Количество устройств' },
    { key: 'playlistCount', title: 'Количество плейлистов' },
    { key: 'filesCount', title: 'Количество файлов' },
    { key: 'organisationName', title: 'Организация' },
    { key: 'isBlocked', title: '' },
    {
      key: 'actions',
      title: (
        <div key="admins-head-button" className={tableStyles.rowButtons}>
          <Button key="admin-create" className={tableStyles.headerButton} onClick={handleCreate}>
            Создать администратора
          </Button>
        </div>
      ),
    },
  ];
  // admin_uldanov@test.test
  const actions = admin => (
    <div key="admin-item-buttons" className={tableStyles.rowButtons}>
      <Button
        variant={ButtonVariantEnum.ICON}
        iconData={{ icon: <Edit /> }}
        title={'Изменить'}
        onClick={() => handleEdit(admin)}
      />
      <Button
        variant={ButtonVariantEnum.ICON}
        iconData={{ icon: <PasswordChange /> }}
        title={'Изменить пароль'}
        onClick={() => handleChangePassword(admin)}
      />
      <Button
        variant={ButtonVariantEnum.ICON}
        iconData={{ icon: admin.isBlocked ? <Lock /> : <PadLock /> }}
        title={admin.isBlocked ? 'Разблокировать' : 'Заблокировать'}
        onClick={() => toggleBlocked(admin)}
      />
    </div>
  );
  const actionsRecovery = admin => (
    <div key="admin-item-buttons" className={tableStyles.rowButtons}>
      <Button
        variant={ButtonVariantEnum.ICON}
        iconData={{ icon: <Recovery /> }}
        title={'Восстановить'}
        onClick={() => handleRecovery(admin)}
      />
    </div>
  );
  const body = admins?.map(admin => {
    const { email, deviceCount, filesCount, playlistCount, isBlocked, isDeleted } = admin || {};

    return {
      email,
      countDevices: deviceCount,
      playlistCount: playlistCount,
      filesCount: filesCount,
      isBlocked: isBlocked ? 'Заблокирован' : isDeleted ? <span style={{ color: '#C24646' }}>Удален</span> : '',
      organisationName: admin?.client?.name,
      actions: isDeleted ? actionsRecovery(admin) : actions(admin),
    };
  });

  return {
    header,
    body,
    isAdminsLoading,
    totalAdminsCount,
  };
};
