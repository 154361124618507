import { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStateContext } from 'store';
import { FileTypes } from 'helpers/constants/fileTypes';
import { renderPPTX } from 'helpers/pptx';
import { Loader } from 'containers/UI/Loader';

import styles from './ModalFilesPreview.module.css';

export const ModalFilesPreview = ({ file = null }) => {
  const { type, resourceId, name } = file;
  const { downloadFileData, fileData, closePopup, setFileData } = useStateContext();
  const [pptxRenderd, setPptxRenderd] = useState(false);

  useLayoutEffect(() => {
    downloadFileData(resourceId, closePopup);

    return () => {
      setFileData(null);
    };
  }, []);

  useEffect(() => {
    if (type === FileTypes.PPTX && resourceId && fileData && !pptxRenderd) {
      setPptxRenderd(true);
      renderPPTX(resourceId, fileData);
    }
  }, [fileData]);

  if (!fileData) {
    return (
      <div className={styles.preview_loader}>
        <Loader size={50} />
      </div>
    );
  }

  return (
    <>
      {type === FileTypes.Image && fileData && resourceId && (
        <img className={styles.preview_wrapper} src={fileData} alt={name} />
      )}
      {type === FileTypes.Audio && fileData && resourceId && (
        <audio className={styles.preview_wrapper} src={fileData} controls />
      )}
      {type === FileTypes.Video && fileData && resourceId && (
        <video className={styles.preview_wrapper} src={fileData} controls />
      )}
      {type === FileTypes.Presentation && fileData && resourceId && (
        <object className={styles.pdf_wrapper} data={fileData} type="application/pdf" />
      )}
      {type === FileTypes.PPTX && fileData && resourceId && <div id={resourceId} className={styles.pptx_wrapper} />}
    </>
  );
};

ModalFilesPreview.propTypes = { file: PropTypes.object };
