import { useEffect, useState } from 'react';
import { generateId } from 'helpers/generateId';
import { showSuccessNotify } from 'helpers/showNotify';
import { useStateContext } from 'store';
import { schedulesService } from 'services';

import {
  convertFromStringIntoSeconds,
  findPlaylistBetweenStart,
  getFreeSpace,
  getFreeSpaceInRange,
  getPlaylistsAbove,
  getPlaylistsUnder,
  moveUnderPlaylists,
  getPlaylistsInRange,
} from '../Helpers/helpers';
import { LOCAL_CHANGES_LENGTH, TIME_INTERVALS, TOTAL_SECONDS } from '../Constants/extra';
import { lastFrom } from 'helpers/lodash';
import { convertTimeFormat } from 'helpers/time/convertTimeFormat';

const secondHeight = 20;

export const useSchedule = schedulingId => {
  const { closePopup, schedulePlaylists, setSchedulePlaylists } = useStateContext();

  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(+TIME_INTERVALS[3].type); // 30 min
  const [start, setStart] = useState(0);
  const [timeWidth, setTimeWidth] = useState(41 + 16); // width + gap
  const [tableWidth, setTableWidth] = useState(1);
  const [localChanges, setLocalChanges] = useState([]);

  const timesOnScreen = Math.floor(tableWidth / timeWidth);
  const endValue = start + timesOnScreen * scale;
  const end = endValue > TOTAL_SECONDS ? TOTAL_SECONDS : endValue;
  const secondsOnScreen = end - start;

  useEffect(() => {
    loadSchedulePlaylists();
  }, []);

  const addStartAndEndAndUniqueIdAndPlaylistId = schedulePlaylists => {
    return schedulePlaylists.reduce((accumulator, playlist) => {
      playlist = {
        ...playlist,
        start: convertFromStringIntoSeconds(playlist.timeFrom),
        end: convertFromStringIntoSeconds(playlist.timeTo),
        playlistId: playlist.id,
        id: generateId(),
      };
      accumulator.push(playlist);

      return accumulator;
    }, []);
  };

  const loadSchedulePlaylists = () => {
    schedulesService.getSchedulePlaylists(schedulingId).then(response => {
      if (response.status === 200) {
        Array.isArray(response?.data) && setSchedulePlaylists(addStartAndEndAndUniqueIdAndPlaylistId(response.data));
      }
    });
  };

  const saveSchedulePlaylists = async e => {
    e.preventDefault();
    const prepareSaveData = schedulePlaylists.map(playlist => ({
      playlistId: playlist.playlistId || playlist.id,
      weekday: playlist.weekday,
      timeFrom: playlist.timeFrom,
      timeTo: playlist.timeTo,
    }));

    await schedulesService.updateSchedulePlaylists(schedulingId, prepareSaveData).then(() => {
      closePopup();
      showSuccessNotify('Расписание успешно изменено');
    });
  };

  const handleTimeChange = timeValue => {
    const findValue = TIME_INTERVALS.find(el => el.type === timeValue);
    setScale(+findValue.type);
  };

  const updateStartValue = (startValue = 0) => {
    const value = (startValue / +scale) * +scale;

    if (value < 0) {
      setStart(0);
    } else if (value + secondsOnScreen >= TOTAL_SECONDS) {
      setStart(TOTAL_SECONDS - secondsOnScreen);
    } else {
      setStart(value);
    }
  };

  const pushLocalChange = payload => {
    if (localChanges.length >= LOCAL_CHANGES_LENGTH) {
      setLocalChanges(prev => {
        prev.shift();
        return prev;
      });
    }
    setLocalChanges(prev => [...prev, payload]);
  };

  const undo = () => {
    if (localChanges.length <= 1) return;

    localChanges.pop();
    setLocalChanges(JSON.parse(JSON.stringify(lastFrom(localChanges))));
  };

  const createPlaylist = ({ playlistId, weekday, name = '', start, end, timeFrom, timeTo }) => {
    const playlist = {
      id: generateId(),
      name,
      weekday,
      playlistId,
      timeFrom,
      timeTo,
      start,
      end,
    };

    setSchedulePlaylists(prev => [...prev, playlist]);
  };

  const createPlaylistDragAndDrop = ({ playlistId, weekday, name = '', start, end }) => {
    const playlists = schedulePlaylists.filter(el => el.weekday === weekday);
    const freeSpace = getFreeSpace(playlists);

    if (freeSpace <= 1) throw new Error('no space in day');

    const playlist = {
      id: generateId(),
      name,
      weekday,
      playlistId,
      timeFrom: null,
      timeTo: null,
      start: null,
      end: null,
    };
    const playlistBetweenStart = findPlaylistBetweenStart(start, playlists);

    if (playlistBetweenStart) {
      if (playlistBetweenStart.end === TOTAL_SECONDS) throw new Error('useSchedule.js createPlaylist() no space');

      const playlistsUnder = getPlaylistsUnder(playlistBetweenStart.end + 1, playlists);

      if (playlistsUnder.length) {
        const playlistUnder = playlistsUnder[0];
        const freeSpaceInRange = getFreeSpaceInRange(playlistBetweenStart.end + 1, playlistsUnder);
        if (freeSpaceInRange <= 0) throw new Error('no space');

        moveUnderPlaylists(playlistsUnder, playlists);
        playlist.end = playlistUnder.start - 1;
        playlist.start = playlistBetweenStart.end + 1;

        playlist.timeFrom = convertTimeFormat(playlist.start);
        playlist.timeTo = convertTimeFormat(playlist.end);

        if (playlist.end > TOTAL_SECONDS) throw new Error('no space');

        setSchedulePlaylists(prev => [...prev, playlist]);
        return;
      }
    }

    const playlistsUnder = getPlaylistsUnder(start, playlists);
    const playlistsAbove = getPlaylistsAbove(start, playlists);
    playlist.end = playlistsUnder.length ? playlistsUnder[0].start - 1 : end;
    playlist.start = playlistsAbove.length ? playlistsAbove[0].end + 1 : start;

    playlist.timeFrom = convertTimeFormat(playlist.start);
    playlist.timeTo = convertTimeFormat(playlist.end);

    if (playlist.end > TOTAL_SECONDS) throw new Error('no space');

    setSchedulePlaylists(prev => [...prev, playlist]);
  };

  const handleLocalUpdate = updatedPlaylist => {
    const filteredSchedulePlaylists = schedulePlaylists.filter(playlist => playlist.id !== updatedPlaylist.id);
    setSchedulePlaylists([...filteredSchedulePlaylists, updatedPlaylist]);
  };

  const deletePlaylist = playlistId => {
    setSchedulePlaylists(prev => prev.filter(playlist => playlist.id !== playlistId));
  };

  const deleteWeekdayPlaylist = (playlistId, weekday) => {
    setSchedulePlaylists(prev =>
      prev.filter(playlist => {
        if (playlist.weekday === weekday && playlist.id === playlistId) {
          return;
        }
        return playlist;
      }),
    );
  };

  const resizePlaylist = ({ weekday, playlistId, start, end }) => {
    const playlists = schedulePlaylists.filter(el => el.weekday === weekday);
    const playlist = schedulePlaylists.find(p => p.id === playlistId);

    if (!playlist) {
      return;
    }
    const scaleValue = secondHeight * (1 / scale);

    if (start < 0) {
      const playlistsAbove = getPlaylistsAbove(playlist.end, playlists, playlist.id);
      const startValue = playlistsAbove.length ? playlistsAbove[0].end + 1 : 0;

      return (playlist.start = startValue);
    }

    if (end > TOTAL_SECONDS) {
      const playlistsUnder = getPlaylistsUnder(playlist.start, playlists, playlist.id);
      const endValue = playlistsUnder.length ? playlistsUnder[0].start - 1 : TOTAL_SECONDS;

      return (playlist.end = endValue);
    }
    const playlistHeight = (end - start) * scaleValue;
    if (playlistHeight < secondHeight) {
      return;
    }

    const playlistsBetweenStarts = getPlaylistsInRange(start, playlist.start - 1, playlists, playlist.id);
    if (playlistsBetweenStarts.length) {
      const playlistBetweenStarts = playlistsBetweenStarts[0];
      const endValue = start - 1;
      const height = (endValue - playlistBetweenStarts.start) * scaleValue;
      if (height <= secondHeight) {
        return (playlist.start = playlistBetweenStarts.end + 1);
      }
      playlistBetweenStarts.end = endValue;
    }

    const playlistsBetweenEnds = getPlaylistsInRange(playlist.end + 1, end, playlists, playlist.id);

    if (playlistsBetweenEnds.length) {
      const playlistBetweenEnds = playlistsBetweenEnds[playlistsBetweenEnds.length - 1];
      const startValue = end + 1;
      const height = (playlistBetweenEnds.end - startValue) * scaleValue;
      if (height <= secondHeight) {
        return (playlist.end = playlistBetweenEnds.start - 1);
      }
      playlistBetweenEnds.start = startValue;
    }

    playlist.start = start;
    playlist.end = end;
    playlist.timeFrom = convertTimeFormat(playlist.start);
    playlist.timeTo = convertTimeFormat(playlist.end);

    setSchedulePlaylists(prev => {
      if (prev.id === playlist.id) {
        return playlist;
      }
      return prev;
    });

    return playlist;
  };

  return {
    schedulePlaylists,
    loading,
    timeWidth,
    tableWidth,
    scale,
    scaleValue: 1 / scale,
    start,
    setStart,
    end,
    secondsOnScreen,
    totalSeconds: TOTAL_SECONDS,

    setLoading,
    setTimeWidth,
    setTableWidth,

    undo,
    pushLocalChange,
    updateStartValue,
    handleTimeChange,
    createPlaylist,
    deletePlaylist,
    saveSchedulePlaylists,
    handleLocalUpdate,
    resizePlaylist,

    createPlaylistDragAndDrop,
    deleteWeekdayPlaylist,
  };
};
