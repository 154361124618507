import { useSortable } from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useDesktopEditor } from 'containers/Desktops/DesktopEditor/useDesktopEditor';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Drag } from 'icons/drag.svg';
import { Input, InputTypeEnum } from 'containers/UI/Input';
import { isEmpty } from 'helpers/lodash';

import styles from '../Desktops.module.css';

export const AudioFileItem = ({ name = '', id = null, removeAudioFileMethod, type, filesStateAddress, index }) => {
  const { listeners, attributes, setNodeRef, transform, transition } = useSortable({ id });
  const { handleAudioFileDelete } = useDesktopEditor(useFormContext);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div ref={setNodeRef} style={{ transform: `translateY(${transform?.y})`, transition }}>
      <div className={classnames(styles['audio-file-item'])}>
        <Button
          variant={ButtonVariantEnum.TEXT}
          className={styles['desktops-item-drag']}
          {...listeners}
          {...attributes}
        >
          <Drag />
        </Button>

        <div className={styles['audio-file-name']}>{name}</div>

        {type === 'video' && (
          <Input
            style={{ border: !isEmpty(errors) ? '1px solid red' : null }}
            className={styles['duration-input']}
            type={InputTypeEnum.NUMBER}
            placeholder="Длительность воспроизведения"
            {...register(`${filesStateAddress}[${index}].duration`, { required: true, valueAsNumber: true })}
          />
        )}

        <Button
          className={styles['audio-file-delete-btn']}
          variant={ButtonVariantEnum.ICON}
          iconData={{ icon: <Delete /> }}
          onClick={() => handleAudioFileDelete(removeAudioFileMethod)}
        />
      </div>
    </div>
  );
};

AudioFileItem.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  removeAudioFileMethod: () => {},
  type: PropTypes.string,
  filesStateAddress: PropTypes.string,
  index: PropTypes.number,
};
