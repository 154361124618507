import { Table } from 'containers/UI/Table';
import { useArbiters } from './useArbiters';
import PropTypes from 'prop-types';

const Arbiters = () => {
  const { header, body, isArbitersLoading, totalArbitersCount } = useArbiters();

  return (
    <Table
      heading={header}
      body={body}
      isLoading={isArbitersLoading}
      totalEntitiesCount={totalArbitersCount}
      idKey="email"
      emptyText="Вы еще не добавили ни одного судьи"
    />
  );
};

Arbiters.propTypes = { arbiters: PropTypes.array, isLoading: PropTypes.bool };

export default Arbiters;
