import { URLs } from 'helpers/constants/URLs';
import { WidgetTypes } from 'helpers/constants/widgetsConstants';
import { playlistsService, presentationsService, screenTemplatesService } from 'services';
import { Text } from './DesktopEditor/Widgets/Text/Text';
import { roadSign } from './DesktopEditor/Widgets/RoadSigns/RoadSigns';
import { Clock } from './DesktopEditor/Widgets/Clock/Clock';
import { ImageW } from './DesktopEditor/Widgets/ImageW/ImageW';
import { Fuel } from './DesktopEditor/Widgets/Fuel/Fuel';
import { Timer } from './DesktopEditor/Widgets/Timer/Timer';
import { VideoStream } from './DesktopEditor/Widgets/VideoStream/VideoStream';
import { Integration } from './DesktopEditor/Widgets/Integration/Integration';
import { YouTube } from './DesktopEditor/Widgets/YouTube/YouTube';
import { SiteCapture } from './DesktopEditor/Widgets/SiteCapture/SiteCapture';
import { Presentation } from './DesktopEditor/Widgets/Presentation/Presentation';
import { FileTypes } from 'helpers/constants/fileTypes';
import { RunningText } from './DesktopEditor/Widgets/RunningText/RunningText';
import { HTML } from './DesktopEditor/Widgets/HTML/HTML';
import { WidgetTitle } from 'helpers/constants/widgetsConstants';
import { Rss } from './DesktopEditor/Widgets/Rss/Rss';
import { AudioPlaylist } from './DesktopEditor/Widgets/AudioPlaylist/AudioPlaylist';
import { Telegram } from './DesktopEditor/Widgets/Telegram/Telegram';
import { generateId } from 'helpers/generateId';
import { Playlist } from './DesktopEditor/Widgets/Playlist/Playlist';
import { tableauTemplatesService } from 'services/tableauTemplates.service';
import { sportEventsService } from 'services/sportEvents.service';
import { PPTX } from './DesktopEditor/Widgets/PPTX/PPTX';
import { SportScore } from './DesktopEditor/Widgets/SportScore/SportScore';
import { SportPeriod } from './DesktopEditor/Widgets/SportPeriod/SportPeriod';

export const WidgetParamTypeEnum = {
  TEXT: 'text',
  NUMBER: 'number',
  COLOR: 'color',
  SELECT: 'select',
  CHECK: 'check',
  FILE: 'file',
  FUEL: 'fuel',
  DATE: 'date',
  AUDIO_FILES: 'audioFiles',
  VIDEO_FILES: 'videoFiles',
  SLIDER: 'slider',
  CODE: 'code',
  TIME: 'time',
};

const FONTS = [
  'Arial',
  'Arial Black',
  'Comic Sans MS',
  'Courier New',
  'Franklin Gothic Medium',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Lucida Sans Unicode',
  'Microsoft Sans Serif',
  'Palatino Linotype',
  'Sylfaen',
  'Tahoma',
  'Trebuchet MS',
  'Verdana',
  'Times New Roman',
];
export const ANIMATION_TYPES = ['None', 'Top', 'Bottom', 'Left', 'Right'];
export const TEAMS_LIST = () => [
  { name: 'Команда 1', value: 1 },
  { name: 'Команда 2', value: 2 },
];
export const TIMER_MODE_LIST = [
  { name: 'Обратный отсчет до даты/времени', value: 'DateCountdown' },
  { name: 'Обратный отсчет фиксированного времени', value: 'MinutesCountdown' },
  { name: 'Прямой отсчет', value: 'Increment' },
];
export const FUEL_TYPES = ['АИ-92', 'АИ-95', 'АИ-98', 'АИ-100', 'ДТ'];
const FONT_STYLES = ['None', 'Bold', 'Italic', 'Underlined'];
const H_ALIGNMENT = ['None', 'Left', 'Center', 'Right'];
const V_ALIGNMENT = ['None', 'Top', 'Center', 'Bottom'];
const STREAM_SOURCES = ['WebCamera', 'WebStream'];
const PORT_TYPES = ['TCP', 'UDP'];
const LIMITS = [];
const EVENT_TYPES = [
  { name: 'Гол первой команды', value: 'Team1Goal' },
  { name: 'Гол второй команды', value: 'Team2Goal' },
  { name: 'Пауза в игре', value: 'GamePause' },
];
for (let i = 10; i <= 160; i = i + 10) LIMITS.push(i);

const ROAD_SIGNS = {
  mainRoad: 'Главная дорога',
  ...Object.fromEntries(LIMITS.map(l => [[`limit${l}`], `Ограничение максимальной скорости ${l}`])),
  recommended: 'Рекомендуемая скорость 50',
  slipperyRoad: 'Скользкая дорога',
  sideWind: 'Боковой ветер',
  trafficJam: 'Дорожный затор',
  pedestrianCrossings: 'Пешеходные переходы',
  menAtWork: 'Дорожные работы',
  giveWay: 'Уступите дорогу',
  straightOrRight: 'Движение прямо или направо',
  heightLimit: 'Ограничение высоты 4.5',
  noOvertaking: 'Обгон запрещен',
  otherHazards: 'Прочие опасности',
  animals: 'Животные',
};

export const DATETIME_FORMATS = {
  ['YYYY']: 'yyyy',
  ['MM']: 'MM',
  ['DD']: 'dd',
  ['hh']: 'HH',
  ['mm']: 'mm',
  ['ss']: 'ss',
  ['mm:ss']: 'mm:ss',
  ['hh:mm']: 'HH:mm',
  ['hh:mm:ss']: 'HH:mm:ss',
  ['DD hh']: 'dd HH',
  ['DD hh:mm']: 'dd HH:mm',
  ['DD hh:mm:ss']: 'dd HH:mm:ss',
  ['MM/DD']: 'MM/dd',
  ['MM/DD hh']: 'MM/dd HH',
  ['MM/DD hh:mm']: 'MM/dd HH:mm',
  ['MM/DD hh:mm:ss']: 'MM/dd HH:mm:ss',
  ['YYYY/MM']: 'yyyy/MM',
  ['YYYY/MM/DD']: 'yyyy/MM/dd',
  ['YYYY/MM/DD hh']: 'yyyy/MM/dd HH',
  ['YYYY/MM/DD hh:mm']: 'yyyy/MM/dd HH:mm',
  ['YYYY/MM/DD hh:mm:ss']: 'yyyy/MM/dd HH:mm:ss',
  ['DD/MM/YYYY hh:mm:ss']: 'dd.MM.yyyy HH:mm:ss',
};
//DURATION VALUE
const dV = (value, length = 2) => {
  const zeroCount = length - value.toString().length;
  const result = [value];
  for (let i = 0; i < zeroCount; i = i + 1) result.unshift('0');
  return result.join('');
};
//DURATION_FORMATS_SINGLE
export const DFS = {
  ['YYYY']: d => dV(d.years, 4),
  ['MM']: d => dV(d.months),
  ['DD']: d => dV(d.days),
  ['hh']: d => dV(d.hours),
  ['mm']: d => dV(d.minutes),
  ['ss']: d => dV(d.seconds),
  ['mm:ss']: d => [dV(d.minutes), dV(d.seconds)].join(':'),
  ['hh:mm']: d => [dV(d.hours), dV(d.minutes)].join(':'),
  ['hh:mm:ss']: d => [dV(d.hours), dV(d.minutes), dV(d.seconds)].join(':'),
  ['MM/DD']: d => [dV(d.months), dV(d.days)].join('/'),
  ['YYYY/MM']: d => [dV(d.years, 4), dV(d.months)].join('/'),
  ['YYYY/MM/DD']: d => [dV(d.years, 4), dV(d.months), dV(d.days)].join('/'),
};
export const DURATION_FORMATS = {
  ...DFS,
  ['DD hh']: d => [DFS['DD'](d), DFS['hh'](d)].join(' '),
  ['DD hh:mm']: d => [DFS['DD'](d), DFS['hh:mm'](d)].join(' '),
  ['DD hh:mm:ss']: d => [DFS['DD'](d), DFS['hh:mm:ss'](d)].join(' '),
  ['MM/DD hh']: d => [DFS['MM/DD'](d), DFS['hh'](d)].join(' '),
  ['MM/DD hh:mm']: d => [DFS['MM/DD'](d), DFS['hh:mm'](d)].join(' '),
  ['MM/DD hh:mm:ss']: d => [DFS['MM/DD'](d), DFS['hh:mm:ss'](d)].join(' '),
  ['YYYY/MM/DD hh']: d => [DFS['YYYY/MM/DD'](d), DFS['hh'](d)].join(' '),
  ['YYYY/MM/DD hh:mm']: d => [DFS['YYYY/MM/DD'](d), DFS['hh:mm'](d)].join(' '),
  ['YYYY/MM/DD hh:mm:ss']: d => [DFS['YYYY/MM/DD'](d), DFS['hh:mm:ss'](d)].join(' '),
};
export const AUTOSIZE_ENUM = {
  19: 110,
  16: 94,
  14: 80,
  13: 77,
  11: 64,
  10: 60,
  8: 47,
  7: 43.7,
  5: 30,
  4: 27,
  2: 13.4,
};
const TIMEZONES = [];
for (let i = -12; i <= 12; i++) TIMEZONES.push(i.toString());
const IMAGE_STYLES = {
  Stretched: 'Растянуть изображение',
  CutByWidgetSize: 'Обрезать по размеру блока',
  ScaledByWidgetSize: 'Уместить в размер блока',
};

export const FontStyleEnum = {
  None: {},
  Bold: { fontWeight: 'bold' },
  Italic: { fontStyle: 'italic' },
  Underlined: { textDecoration: 'underline' },
};

export const NEW_DESKTOP = {
  name: 'Новый рабочий стол',
  duration: 5,
  width: 1920,
  height: 1080,
  widgets: [],
  animation: { type: ANIMATION_TYPES[0], speed: 0 },
};
const FIELDS = {
  name: attr => ({
    key: 'name',
    type: WidgetParamTypeEnum.TEXT,
    label: 'Имя',
    options: { required: true, minLength: 4, maxLength: 30 },
    ...attr,
  }),
  duration: attr => ({
    key: 'duration',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Длительность показа',
    options: { required: true, min: 1, max: 10000 },
    ...attr,
  }),
  width: attr => ({
    key: 'width',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Ширина',
    options: { required: true, min: 0, max: 10000 },
    ...attr,
  }),
  height: attr => ({
    key: 'height',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Высота',
    options: { required: true, min: 0, max: 10000 },
    ...attr,
  }),
  animationType: attr => ({
    key: 'animation.type',
    type: WidgetParamTypeEnum.SELECT,
    label: 'Тип анимации',
    list: ANIMATION_TYPES.map(value => ({ name: value, value })),
    options: { required: true },
    ...attr,
  }),
  animationSpeed: attr => ({
    key: 'animation.speed',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Скорость анимации',
    options: { required: true, min: 0, defaultValue: 0 },
    ...attr,
  }),
  text: attr => ({
    key: 'text',
    type: WidgetParamTypeEnum.TEXT,
    label: 'Текст',
    options: { required: true, minLength: 0, maxLength: 200 },
    ...attr,
  }),
  font: attr => ({
    key: 'font',
    type: WidgetParamTypeEnum.SELECT,
    list: FONTS.map(value => ({ name: value, value })),
    label: 'Шрифт',
    ...attr,
  }),
  fontSize: attr => ({
    key: 'fontSize',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Размер шрифта',
    options: { required: true, minLength: 1, maxLength: 100 },
    disabled: f => f.fontAutoSize,
    ...attr,
  }),
  fontStyle: attr => ({
    key: 'fontStyle',
    type: WidgetParamTypeEnum.SELECT,
    list: FONT_STYLES.map(value => ({ name: value, value })),
    label: 'Формат текста',
    ...attr,
  }),
  horizontalAlignment: attr => ({
    key: 'horizontalAlignment',
    type: WidgetParamTypeEnum.SELECT,
    list: H_ALIGNMENT.map(value => ({ name: value, value })),
    label: 'Горизонтальное выравнивание',
    ...attr,
  }),
  verticalAlignment: attr => ({
    key: 'verticalAlignment',
    type: WidgetParamTypeEnum.SELECT,
    list: V_ALIGNMENT.map(value => ({ name: value, value })),
    label: 'Вертикальное выравнивание',
    ...attr,
  }),
  comment: attr => ({
    key: 'comment',
    type: WidgetParamTypeEnum.TEXT,
    label: 'Комментарий',
    options: { minLength: 4, maxLength: 100 },
    ...attr,
  }),
  textColor: attr => ({
    key: 'textColor',
    type: WidgetParamTypeEnum.COLOR,
    label: 'Цвет шрифта',
    options: { required: true },
    ...attr,
  }),
  backgroundColor: attr => ({
    key: 'backgroundColor',
    type: WidgetParamTypeEnum.COLOR,
    label: 'Цвет фона',
    options: { required: true },
    ...attr,
  }),
  squareForm: attr => ({
    key: 'squareForm',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Квадратная форма',
    ...attr,
  }),
  roadSign: attr => ({
    key: 'roadSign',
    type: WidgetParamTypeEnum.SELECT,
    label: 'Знак',
    list: Object.keys(ROAD_SIGNS).map(value => ({ value, name: ROAD_SIGNS[value] })),
    ...attr,
  }),
  timeFormat: attr => ({
    key: 'timeFormat',
    type: WidgetParamTypeEnum.SELECT,
    list: Object.keys(DATETIME_FORMATS).map(value => ({ value, name: DATETIME_FORMATS[value] })),
    label: 'Формат отображения времени',
    ...attr,
  }),
  timeZone: attr => ({
    key: 'timeZone',
    type: WidgetParamTypeEnum.SELECT,
    label: 'Часовой пояс',
    list: TIMEZONES.map(value => ({ name: value, value })),
    ...attr,
  }),
  fontAutoSize: attr => ({
    key: 'fontAutoSize',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Авторазмер шрифта',
    ...attr,
  }),
  imageStyle: attr => ({
    key: 'imageStyle',
    type: WidgetParamTypeEnum.SELECT,
    list: Object.keys(IMAGE_STYLES).map(value => ({ value, name: IMAGE_STYLES[value] })),
    label: 'Позиция',
    ...attr,
  }),
  file: attr => ({
    key: 'file',
    type: WidgetParamTypeEnum.FILE,
    label: 'Изображение',
    options: { required: true },
    ...attr,
  }),
  cost: attr => ({
    key: 'cost',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Цена',
    options: { required: true, min: 0, max: 100000000 },
    ...attr,
  }),
  fullScreen: attr => ({ key: 'fullScreen', type: WidgetParamTypeEnum.CHECK, label: 'На весь экран', ...attr }),
  fuels: attr => ({ key: 'fuels', type: WidgetParamTypeEnum.FUEL, label: 'Типы топлива', ...attr }),
  finishDate: attr => ({
    key: 'finishDate',
    type: WidgetParamTypeEnum.DATE,
    label: 'Дата окончания',
    minDate: () => new Date(),
    disabled: f => f.timerMode === 'MinutesCountdown' || f.timerMode === 'Increment' || !f.timerMode,
    ...attr,
  }),
  streamSource: attr => ({
    key: 'streamSource',
    type: WidgetParamTypeEnum.SELECT,
    list: STREAM_SOURCES.map(value => ({ name: value, value })),
    label: 'Тип источника видеопотока',
    ...attr,
  }),
  url: attr => ({
    key: 'url',
    type: WidgetParamTypeEnum.TEXT,
    label: 'URL',
    options: { required: true, minLength: 4, maxLength: 500 },
    ...attr,
  }),
  port: attr => ({
    key: 'port',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Порт',
    options: { required: true, min: 1, max: 100000 },
    ...attr,
  }),
  portType: attr => ({
    key: 'portType',
    type: WidgetParamTypeEnum.SELECT,
    list: PORT_TYPES.map(value => ({ name: value, value })),
    label: 'Порт',
    ...attr,
  }),
  wordWrapping: attr => ({ key: 'wordWrapping', type: WidgetParamTypeEnum.CHECK, label: 'Перенос слов', ...attr }),
  looped: attr => ({ key: 'looped', type: WidgetParamTypeEnum.CHECK, label: 'Зациклить (on/off)', ...attr }),
  websiteBlockX: attr => ({
    key: 'websiteBlock.x',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'X',
    options: { required: true, min: 0, max: 100000 },
    ...attr,
  }),
  websiteBlockY: attr => ({
    key: 'websiteBlock.y',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Y',
    options: { required: true, min: 0, max: 100000 },
    ...attr,
  }),
  websiteBlockHeight: attr => ({
    key: 'websiteBlock.height',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Длина',
    options: { required: true, min: 0, max: 100000 },
    disabled: f => f.scaling,
    ...attr,
  }),
  websiteBlockWidth: attr => ({
    key: 'websiteBlock.width',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Ширина',
    options: { required: true, min: 0, max: 100000 },
    disabled: f => f.scaling,
    ...attr,
  }),
  originalProportions: attr => ({
    key: 'originalProportions',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Сохранять пропорции',
    ...attr,
  }),
  scaling: attr => ({
    key: 'scaling',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Масштабировать',
    ...attr,
  }),
  pageNumber: attr => ({
    key: 'pageNumber',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Номер страницы по умолчанию',
    options: { required: true, min: 1, max: 1000 },
    ...attr,
  }),
  speed: attr => ({
    key: 'speed',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Скорость',
    options: { required: true, min: 1, max: 100 },
    ...attr,
  }),
  code: attr => ({
    key: 'code',
    type: WidgetParamTypeEnum.CODE,
    label: 'HTML код',
    options: { max: 1048576 },
    ...attr,
  }),
  recordCount: attr => ({
    key: 'recordCount',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Количество записей',
    options: { required: true, min: 1, max: 30 },
    ...attr,
  }),
  refreshInterval: attr => ({
    key: 'refreshInterval',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Интервал обновления',
    options: { required: true, min: 1, max: 100 },
    ...attr,
  }),
  css: attr => ({
    key: 'css',
    type: WidgetParamTypeEnum.TEXT,
    label: 'Стили',
    ...attr,
  }),
  audioFiles: attr => ({
    key: 'audioFiles',
    type: WidgetParamTypeEnum.AUDIO_FILES,
    label: 'Список файлов',
    ...attr,
  }),
  audioVolume: attr => ({
    key: 'audioVolume',
    type: WidgetParamTypeEnum.SLIDER,
    label: 'Громкость',
    options: { required: true, min: 1, max: 11 },
    ...attr,
  }),
  started: attr => ({
    key: 'started',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Воспроизведение (on/off)',
    ...attr,
  }),
  videoFiles: attr => ({
    key: 'videoFiles',
    type: WidgetParamTypeEnum.VIDEO_FILES,
    label: 'Список файлов',
    ...attr,
  }),
  replaceableOnGameEvent: attr => ({
    key: 'replaceableOnGameEvent',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Смена стола по событию в игре',
    ...attr,
  }),
  actionEvent: attr => ({
    key: 'action.event',
    type: WidgetParamTypeEnum.SELECT,
    list: EVENT_TYPES,
    label: 'Событие для воспроизведения рабочего стола',
    options: { required: false },
    disabled: f => !f.replaceableOnGameEvent,
    ...attr,
  }),
  actionDuration: attr => ({
    key: 'action.duration',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Длительность воспроизведения',
    disabled: f => !f.replaceableOnGameEvent,
    ...attr,
  }),
  visible: attr => ({
    key: 'visible',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Видимость',
    ...attr,
  }),
  team: (attr, data) => ({
    key: 'team',
    type: WidgetParamTypeEnum.SELECT,
    list: TEAMS_LIST(data),
    label: 'Команда',
    ...attr,
  }),
  timerMode: attr => ({
    key: 'timerMode',
    type: WidgetParamTypeEnum.SELECT,
    label: 'Режим работы',
    list: TIMER_MODE_LIST,
    options: { required: false },
    ...attr,
  }),
  timerDuration: attr => ({
    key: 'duration',
    type: WidgetParamTypeEnum.NUMBER,
    label: 'Длительность показа',
    options: { min: 1, max: 10000 },
    disabled: f => f.timerMode === 'DateCountdown' || !f.timerMode,
    ...attr,
  }),
  timerStarted: attr => ({
    key: 'started',
    type: WidgetParamTypeEnum.CHECK,
    label: 'Воспроизведение (on/off)',
    disabled: f => f.timerMode === 'DateCountdown' || !f.timerMode,
    ...attr,
  }),
};
export const DESKTOP_FORM = [
  FIELDS.name({ label: 'Название' }),
  FIELDS.duration(),
  FIELDS.width(),
  FIELDS.height(),
  FIELDS.animationType({ defaultValue: 'None' }),
  FIELDS.animationSpeed(),
];

export const DESKTOP_TABLEAU_TEMPLATE_FORM = [FIELDS.name({ label: 'Название' }), FIELDS.width(), FIELDS.height()];
export const DESKTOP_SPORT_EVENTS_FORM = [
  FIELDS.name({ label: 'Название' }),
  FIELDS.width(),
  FIELDS.height(),
  FIELDS.replaceableOnGameEvent(),
  FIELDS.actionEvent(),
  FIELDS.actionDuration(),
];

export const WIDGETS_FORMS = ({ data }) => ({
  [WidgetTypes.Text]: [
    FIELDS.name(),
    FIELDS.text(),
    FIELDS.font(),
    FIELDS.fontSize(),
    FIELDS.fontStyle(),
    FIELDS.horizontalAlignment(),
    FIELDS.verticalAlignment(),
    FIELDS.comment(),
    FIELDS.textColor(),
    FIELDS.backgroundColor(),
    FIELDS.squareForm(),
  ],
  [WidgetTypes.RoadSign]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.backgroundColor(),
    FIELDS.roadSign(),
    FIELDS.squareForm(),
  ],
  [WidgetTypes.Clock]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.font(),
    FIELDS.fontStyle(),
    FIELDS.fontSize(),
    FIELDS.timeFormat(),
    FIELDS.timeZone(),
    FIELDS.textColor(),
    FIELDS.backgroundColor(),
    FIELDS.fontAutoSize(),
    FIELDS.squareForm(),
  ],
  [WidgetTypes.Image]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.imageStyle(),
    FIELDS.file({ fileType: FileTypes.Image }),
    FIELDS.backgroundColor(),
    FIELDS.fullScreen(),
    FIELDS.squareForm(),
  ],
  [WidgetTypes.Fuel]: [FIELDS.name(), FIELDS.comment(), FIELDS.backgroundColor(), FIELDS.squareForm(), FIELDS.fuels()],
  FuelType: [
    FIELDS.font({ key: 'typeFont', label: 'Шрифт типа топлива' }),
    FIELDS.fontSize({ key: 'typeFontSize', label: 'Размер шрифта типа топлива' }),
    FIELDS.fontStyle({ key: 'typeFontStyle', label: 'Формат текста типа топлива' }),
    FIELDS.cost(),
    FIELDS.font({ key: 'costFont', label: 'Шрифт цены топлива' }),
    FIELDS.fontSize({ key: 'costFontSize', label: 'Размер шрифта цены топлива' }),
    FIELDS.fontStyle({ key: 'costFontStyle', label: 'Формат текста цены топлива' }),
  ],
  [WidgetTypes.Timer]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.font(),
    FIELDS.fontSize(),
    FIELDS.fontStyle(),
    FIELDS.timeFormat(),
    FIELDS.finishDate(),
    FIELDS.textColor(),
    FIELDS.backgroundColor(),
    FIELDS.fontAutoSize(),
    FIELDS.squareForm(),
    FIELDS.timerMode(),
    FIELDS.timerDuration(),
    FIELDS.timerStarted(),
  ],
  [WidgetTypes.VideoStream]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.squareForm(),
    FIELDS.streamSource(),
    FIELDS.url(),
  ],
  [WidgetTypes.Integration]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.textColor(),
    FIELDS.backgroundColor(),
    FIELDS.port(),
    FIELDS.portType(),
    FIELDS.font(),
    FIELDS.fontSize(),
    FIELDS.fontStyle(),
    FIELDS.horizontalAlignment(),
    FIELDS.verticalAlignment(),
    FIELDS.wordWrapping(),
    FIELDS.squareForm(),
  ],
  [WidgetTypes.YouTube]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.url(),
    FIELDS.fullScreen(),
    FIELDS.squareForm(),
    FIELDS.looped(),
  ],
  [WidgetTypes.SiteCapture]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.url(),
    FIELDS.backgroundColor(),
    FIELDS.websiteBlockX(),
    FIELDS.websiteBlockY(),
    FIELDS.websiteBlockHeight(),
    FIELDS.websiteBlockWidth(),
    FIELDS.scaling(),
    FIELDS.originalProportions(),
    FIELDS.squareForm(),
  ],
  [WidgetTypes.Presentation]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.file({ fileType: FileTypes.Presentation, label: 'Файл' }),
    FIELDS.pageNumber(),
    FIELDS.fullScreen(),
    FIELDS.squareForm(),
  ],
  [WidgetTypes.RunningText]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.backgroundColor(),
    FIELDS.textColor(),
    FIELDS.text(),
    FIELDS.font(),
    FIELDS.fontStyle(),
    FIELDS.squareForm(),
    FIELDS.verticalAlignment(),
    FIELDS.horizontalAlignment(),
    FIELDS.speed({ required: true, minLength: 4, maxLength: 500 }),
  ],
  [WidgetTypes.Html]: [FIELDS.name(), FIELDS.comment(), FIELDS.squareForm(), FIELDS.code()],
  [WidgetTypes.Rss]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.squareForm(),
    FIELDS.url({ options: { required: true, minLength: 4, maxLength: 500 } }),
    FIELDS.recordCount({ minLength: 1, maxLength: 30 }),
    FIELDS.refreshInterval({ minLength: 1, maxLength: 100 }),
    FIELDS.css(),
  ],
  [WidgetTypes.AudioPlaylist]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.audioFiles(),
    FIELDS.looped(),
    FIELDS.audioVolume(),
    FIELDS.started(),
  ],
  [WidgetTypes.Telegram]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.url(),
    FIELDS.recordCount(),
    FIELDS.refreshInterval({ label: 'Частота обновления' }),
    FIELDS.backgroundColor(),
    FIELDS.textColor(),
    FIELDS.font(),
    FIELDS.fontSize(),
  ],
  [WidgetTypes.Playlist]: [
    FIELDS.backgroundColor(),
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.squareForm(),
    FIELDS.imageStyle(),
    FIELDS.videoFiles(),
    FIELDS.looped(),
    FIELDS.fullScreen(),
  ],
  [WidgetTypes.PPTX]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.file({ fileType: FileTypes.PPTX, label: 'Файл' }),
    FIELDS.fullScreen(),
    FIELDS.squareForm(),
    FIELDS.pageNumber(),
  ],
  [WidgetTypes.SportScore]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.visible(),
    FIELDS.backgroundColor(),
    FIELDS.squareForm(),
    FIELDS.textColor(),
    FIELDS.font(),
    FIELDS.fontSize(),
    FIELDS.fontStyle(),
    FIELDS.horizontalAlignment(),
    FIELDS.verticalAlignment(),
    FIELDS.team({}, data),
  ],
  [WidgetTypes.SportPeriod]: [
    FIELDS.name(),
    FIELDS.comment(),
    FIELDS.visible(),
    FIELDS.backgroundColor(),
    FIELDS.squareForm(),
    FIELDS.textColor(),
    FIELDS.font(),
    FIELDS.fontSize(),
    FIELDS.fontStyle(),
    FIELDS.horizontalAlignment(),
    FIELDS.verticalAlignment(),
  ],
});
export const WIDGETS_DEFAULT = {
  [WidgetTypes.Text]: {
    name: WidgetTitle[WidgetTypes.Text],
    text: '',
    fontSize: 12,
    font: FONTS[0],
    fontStyle: FONT_STYLES[0],
    horizontalAlignment: H_ALIGNMENT[0],
    verticalAlignment: V_ALIGNMENT[0],
    comment: '',
    textColor: '#FFFFFF',
    backgroundColor: 'transparent',
    squareForm: false,
  },
  [WidgetTypes.RoadSign]: {
    name: WidgetTitle[WidgetTypes.RoadSign],
    comment: '',
    backgroundColor: 'transparent',
    roadSign: 'mainRoad',
    squareForm: false,
  },
  [WidgetTypes.Clock]: {
    name: WidgetTitle[WidgetTypes.Clock],
    comment: '',
    font: FONTS[0],
    fontStyle: FONT_STYLES[0],
    fontSize: 12,
    timeFormat: 'hh:mm:ss',
    timeZone: '0',
    textColor: '#FFFFFF',
    backgroundColor: 'transparent',
    fontAutoSize: false,
    squareForm: false,
  },
  [WidgetTypes.Image]: {
    name: WidgetTitle[WidgetTypes.Image],
    comment: '',
    imageStyle: 'Stretched',
    file: '',
    backgroundColor: 'transparent',
    fullScreen: false,
    squareForm: false,
  },
  [WidgetTypes.Fuel]: {
    name: WidgetTitle[WidgetTypes.Fuel],
    comment: '',
    backgroundColor: 'transparent',
    fuels: [],
  },
  FuelType: {
    type: '',
    typeFont: FONTS[0],
    typeFontSize: 12,
    typeFontStyle: FONT_STYLES[0],
    cost: 0,
    costFont: FONTS[0],
    costFontSize: 12,
    costFontStyle: FONT_STYLES[0],
  },
  [WidgetTypes.Timer]: {
    name: WidgetTitle[WidgetTypes.Timer],
    comment: '',
    font: FONTS[0],
    fontStyle: FONT_STYLES[0],
    fontSize: 12,
    timeFormat: 'hh:mm:ss',
    finishDate: '',
    textColor: '#FFFFFF',
    backgroundColor: 'transparent',
    fontAutoSize: false,
    squareForm: false,
    timerMode: null,
    timerDuration: 0,
    started: false,
  },
  [WidgetTypes.VideoStream]: {
    name: WidgetTitle[WidgetTypes.VideoStream],
    comment: '',
    squareForm: false,
    streamSource: STREAM_SOURCES[0],
    url: '',
  },
  [WidgetTypes.Integration]: {
    name: WidgetTitle[WidgetTypes.Integration],
    text: '',
    port: 0,
    portType: PORT_TYPES[0],
    fontSize: 12,
    font: FONTS[0],
    fontStyle: FONT_STYLES[0],
    horizontalAlignment: H_ALIGNMENT[0],
    verticalAlignment: V_ALIGNMENT[0],
    comment: '',
    textColor: '#FFFFFF',
    backgroundColor: 'transparent',
    wordWrapping: true,
    squareForm: false,
  },
  [WidgetTypes.YouTube]: {
    name: WidgetTitle[WidgetTypes.YouTube],
    comment: '',
    url: '',
    squareForm: false,
    fullScreen: false,
    looped: false,
  },
  [WidgetTypes.SiteCapture]: {
    name: WidgetTitle[WidgetTypes.SiteCapture],
    comment: '',
    url: '',
    backgroundColor: 'transparent',
    websiteBlock: { x: 0, y: 0, width: 100, height: 100 },
    scaling: false,
    originalProportions: false,
    squareForm: false,
  },
  [WidgetTypes.Presentation]: {
    name: WidgetTitle[WidgetTypes.Presentation],
    comment: '',
    file: '',
    pageNumber: 1,
    fullScreen: false,
    squareForm: false,
  },
  [WidgetTypes.RunningText]: {
    name: WidgetTitle[WidgetTypes.RunningText],
    comment: '',
    textColor: '#FFFFFF',
    backgroundColor: 'transparent',
    text: '',
    fontSize: 12,
    font: FONTS[0],
    fontStyle: FONT_STYLES[0],
    verticalAlignment: V_ALIGNMENT[0],
    horizontalAlignment: H_ALIGNMENT[0],
    speed: 1,
    squareForm: false,
  },
  [WidgetTypes.Html]: {
    name: WidgetTitle[WidgetTypes.Html],
    comment: '',
    squareForm: false,
    code: '',
  },
  [WidgetTypes.Rss]: {
    name: WidgetTitle[WidgetTypes.Rss],
    comment: '',
    squareForm: false,
    backgroundColor: 'transparent',
    url: '',
    recordCount: 1,
    refreshInterval: 1,
    css: '.feed{text-align:left;color:#000;height:100%}h1{color:#fff;margin:5px;font-size:20px}h3{margin:0;padding:0}img{width:100%}a{color:#42b983}.article{position:relative;display:flex;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;background-clip:border-box;border:1px solid rgba(0,0,0,.125);border-radius:.25rem;padding:1.25rem;margin-bottom:15px}.body .content{margin-top:5px}.footer{font-size:13px;color:#777;display:flex;align-items:flex-end}.middot{font-size:12px;margin:0 8px}.error{color:red}\n',
  },
  [WidgetTypes.AudioPlaylist]: {
    name: WidgetTitle[WidgetTypes.AudioPlaylist],
    comment: '',
    backgroundColor: 'transparent',
    started: false,
    looped: false,
    audioFiles: [],
    audioVolume: 1,
  },
  [WidgetTypes.Telegram]: {
    name: WidgetTitle[WidgetTypes.Telegram],
    comment: '',
    url: 'https://telegram.org/js/telegram-widget.js?22',
    recordCount: 1,
    refreshInterval: 60,
    textColor: '#FFFFFF',
    backgroundColor: 'transparent',
    fontSize: 12,
    font: FONTS[0],
  },
  [WidgetTypes.Playlist]: {
    name: WidgetTitle[WidgetTypes.Playlist],
    backgroundColor: 'transparent',
    comment: '',
    squareForm: false,
    videoFiles: [],
    looped: false,
    fullScreen: false,
    imageStyle: 'Stretched',
  },
  [WidgetTypes.PPTX]: {
    name: WidgetTitle[WidgetTypes.PPTX],
    comment: '',
    file: '',
    fullScreen: false,
    squareForm: false,
    pageNumber: 1,
  },
  [WidgetTypes.SportScore]: {
    name: WidgetTitle[WidgetTypes.SportScore],
    comment: '',
    visible: false,
    backgroundColor: 'transparent',
    squareForm: false,
    textColor: '#FFFFFF',
    font: FONTS[0],
    fontSize: 12,
    fontStyle: FONT_STYLES[0],
    horizontalAlignment: H_ALIGNMENT[0],
    verticalAlignment: V_ALIGNMENT[0],
    team: '',
  },
  [WidgetTypes.SportPeriod]: {
    name: WidgetTitle[WidgetTypes.SportPeriod],
    comment: '',
    visible: false,
    backgroundColor: 'transparent',
    squareForm: false,
    textColor: '#FFFFFF',
    font: FONTS[0],
    fontSize: 12,
    fontStyle: FONT_STYLES[0],
    horizontalAlignment: H_ALIGNMENT[0],
    verticalAlignment: V_ALIGNMENT[0],
  },
};
export const WIDGETS_COMPONENTS = {
  [WidgetTypes.Text]: Text,
  [WidgetTypes.RoadSign]: roadSign,
  [WidgetTypes.Clock]: Clock,
  [WidgetTypes.Image]: ImageW,
  [WidgetTypes.Fuel]: Fuel,
  [WidgetTypes.Timer]: Timer,
  [WidgetTypes.VideoStream]: VideoStream,
  [WidgetTypes.Integration]: Integration,
  [WidgetTypes.YouTube]: YouTube,
  [WidgetTypes.SiteCapture]: SiteCapture,
  [WidgetTypes.Presentation]: Presentation,
  [WidgetTypes.RunningText]: RunningText,
  [WidgetTypes.Html]: HTML,
  [WidgetTypes.Rss]: Rss,
  [WidgetTypes.AudioPlaylist]: AudioPlaylist,
  [WidgetTypes.Telegram]: Telegram,
  [WidgetTypes.Playlist]: Playlist,
  [WidgetTypes.PPTX]: PPTX,
  [WidgetTypes.SportScore]: SportScore,
  [WidgetTypes.SportPeriod]: SportPeriod,
};

export const encodeWidget = ({ type, ...w }, params = {}) => {
  const { dontDeleteId = false, dontDeleteVisible = false, deleteTimestamp = false } = params;

  const { Integration, Text, Fuel, Clock, RoadSign, Timer, SiteCapture, Presentation, Image, PPTX } = WidgetTypes;
  const widget = {
    ...w,
    type,
    squareForm: !!w?.squareForm,
    position: {
      x: Math.round(w?.left || w?.position?.x || 0),
      y: Math.round(w?.top || w?.position?.y || 0),
      width: Math.round(w?.width || w?.position?.width || 0),
      height: Math.round(w?.height || w?.position?.height || 0),
    },
  };
  if (!dontDeleteId) {
    delete widget.id;
  }
  if (!dontDeleteVisible) {
    delete widget.visible;
  }

  if (deleteTimestamp) {
    delete widget.timestamp;
  }

  delete widget.left;
  delete widget.top;
  delete widget.width;
  delete widget.height;

  if (type === Text) {
    widget.fontSize = +w.fontSize;
  }
  if (type === Clock) {
    widget.fontSize = +w.fontSize;
    widget.timeZone = +w.timeZone;
    widget.fontAutoSize = !!w?.fontAutoSize;
  }
  if (type === RoadSign) {
    widget.roadSign = ROAD_SIGNS[w.roadSign];
  }
  if (type === Fuel) {
    widget.fuels = w.fuels.map(f => ({
      ...f,
      typeFontSize: +f.typeFontSize,
      costFontSize: +f.costFontSize,
      cost: +f.cost,
    }));
  }
  if (type === Timer) {
    widget.fontSize = +w.fontSize;
    widget.finishDate = w.finishDate.toString();
  }
  if (type === Integration) {
    widget.fontSize = +w.fontSize;
    widget.port = +w.port;
  }
  if (type === SiteCapture) {
    widget.websiteBlock.x = +w.websiteBlock.x;
    widget.websiteBlock.y = +w.websiteBlock.y;
    widget.websiteBlock.height = +w.websiteBlock.height;
    widget.websiteBlock.width = +w.websiteBlock.width;
  }
  if (type === Presentation) {
    widget.pageNumber = +w.pageNumber;
  }
  if (type === Image || type === Presentation || type === PPTX) {
    widget.file = { id: w?.file };
  }

  return widget;
};

export const encodeCompareForm = ({ screens }) => {
  return screens?.map(s => {
    const newScreen = {
      ...s,
      duration: +s.duration,
      width: +s.width,
      height: +s.height,
      animation: {
        type: s?.animation?.type || 'None',
        speed: +s?.animation?.speed || 0,
      },
      widgets: s.widgets.map(el => encodeWidget(el, { dontDeleteVisible: true, deleteTimestamp: true })),
    };

    delete newScreen.duration; // todo temp solution - delete after fix back
    delete newScreen.id;
    delete newScreen.ourId;
    delete newScreen.timestamp;

    return newScreen;
  });
};

export const encodeForm = ({ screens }, dontDeleteId = false) => {
  return screens?.map(s => {
    const newScreen = {
      ...s,
      duration: +s.duration,
      width: +s.width,
      height: +s.height,
      animation: {
        type: s?.animation?.type || 'None',
        speed: +s?.animation?.speed || 0,
      },
      widgets: s.widgets.map(el => encodeWidget(el, { dontDeleteId, dontDeleteVisible: dontDeleteId })),
    };

    if ('replaceableOnGameEvent' in newScreen) {
      newScreen.replaceableOnGameEvent = !!newScreen.replaceableOnGameEvent;
    }

    if (isNaN(s?.action?.duration) && !s?.action?.event) {
      delete newScreen.action;
    } else {
      newScreen.action = {};
      if (!isNaN(s.action?.duration)) {
        newScreen.action.duration = +s.action?.duration;
      }
      if (s.action.event) {
        newScreen.action.event = s.action.event;
      }
    }
    if (!dontDeleteId) {
      delete newScreen.id;
      delete newScreen.ourId;
    }

    return newScreen;
  });
};

export const decodeWidget = ({ type, ...w }) => {
  const { Clock, RoadSign, Timer, Image, Presentation, AudioPlaylist, Playlist } = WidgetTypes;
  const widget = {
    ...w,
    type,
    left: w?.position?.x,
    top: w?.position?.y,
    width: w?.position?.width,
    height: w?.position?.height,
  };

  if (type === Clock) {
    widget.timeZone = w?.timeZone?.toString();
  }
  if (type === RoadSign) {
    widget.roadSign = Object.entries(ROAD_SIGNS).reduce(
      (acc, [key, value]) => (w.roadSign === value ? acc + key : acc),
      '',
    );
  }
  if (type === Timer) {
    widget.finishDate = new Date(w.finishDate);
  }
  if (type === Image || type === Presentation) {
    widget.file = w?.file?.id;
  }

  if (type === AudioPlaylist) {
    if (widget?.audioFiles) {
      widget.audioFiles = widget.audioFiles.map(file => {
        return {
          ...file,
          uniqueId: generateId(),
        };
      });
    }
  }

  if (type === Playlist) {
    if (widget?.videoFiles) {
      widget.videoFiles = widget.videoFiles.map(file => {
        return {
          ...file,
          uniqueId: generateId(),
        };
      });
    }
  }

  return widget;
};

export const decodeForm = screens => {
  return screens?.map(s => ({
    ...s,
    widgets: s.widgets.map(decodeWidget),
  }));
};

export const getSelectedRangeStyle = (coords, sizeCoeff) => {
  const { top, left, x, y } = coords;
  const newTop = x < top ? x : top;
  const newLeft = y < left ? y : left;

  return {
    top: newTop / (sizeCoeff / 100),
    left: newLeft / (sizeCoeff / 100),
    width: Math.abs((left - y) / (sizeCoeff / 100)),
    height: Math.abs((top - x) / (sizeCoeff / 100)),
  };
};

export const isWidgetInsideSelectedRange = (range, widget) => {
  const { top: rangeTop, left: rangeLeft, width: rangeWidth, height: rangeHeight } = range;
  const { top: wTop, left: wLeft, width: wWidth, height: wHeight } = widget;

  return (
    rangeTop <= wTop &&
    rangeLeft <= wLeft &&
    rangeLeft + rangeWidth >= wLeft + wWidth &&
    rangeTop + rangeHeight >= wTop + wHeight
  );
};

export const getEditorSettings = pathname => {
  const moduleEditor = pathname.split('/')[1];

  switch (moduleEditor) {
    case 'playlists':
      return {
        service: playlistsService,
        originPage: URLs.playlists,
        method: playlistsService.getPlaylist,
        location: LOCATION_PLAYLISTS,
        moduleEditor,
      };
    case 'screenTemplates':
      return {
        service: screenTemplatesService,
        originPage: URLs.screenTemplates,
        method: screenTemplatesService.getById,
        location: LOCATION_SCREEN_TEMPLATES,
        moduleEditor,
      };
    case 'presentations':
      return {
        service: presentationsService,
        originPage: URLs.presentations,
        method: presentationsService.getPresentation,
        location: LOCATION_PRESENTATIONS,
        moduleEditor,
      };
    case 'tableauTemplates':
      return {
        service: tableauTemplatesService,
        originPage: URLs.scoreboardTemplates,
        method: tableauTemplatesService.getById,
        location: LOCATION_TABLEAU_TEMPLATES,
        moduleEditor,
      };
    case 'sportEvents':
      return {
        service: sportEventsService,
        originPage: URLs.sportEvents,
        method: sportEventsService.getById,
        location: LOCATION_SPORT_EVENTS,
        moduleEditor,
      };
  }
};

export const LOCATION_PLAYLISTS = 'LOCATION_PLAYLISTS';
export const LOCATION_SCREEN_TEMPLATES = 'LOCATION_SCREEN_TEMPLATES';
export const LOCATION_PRESENTATIONS = 'LOCATION_PRESENTATIONS';
export const LOCATION_TABLEAU_TEMPLATES = 'LOCATION_TABLEAU_TEMPLATES';
export const LOCATION_SPORT_EVENTS = 'LOCATION_SPORT_EVENTS';
export const MODAL_DESKTOP_TEMPLATES_PAGE_SIZE = 4;
export const CREATE_NEW_TEMPLATE_MODE = 'create';
export const SAVE_AS_TEMPLATE_MODE = 'save';
export const RESIZE_DIRECTION_PLUS = '+';
export const RESIZE_DIRECTION_MINUS = '-';

export const SPORT_SCORE_WIDGET_TYPE = 'SportScore';
export const SPORT_PERIOD_WIDGET_TYPE = 'SportPeriod';

export const getWidgetTranslate = (transformX, transformY, sizeCoeff) => {
  return { transform: `translate3d(${transformX / (sizeCoeff / 100)}px, ${transformY / (sizeCoeff / 100)}px, 0)` };
};

export const getWidgetPositionParamsAfterMouseResolve = (top, left, sizeCoeff, transform) => {
  if (!transform || (transform.x < 1 && transform.y < 1)) {
    return { top, left };
  }

  return { top: top / (sizeCoeff / 100), left: left / (sizeCoeff / 100) };
};

export const calculateSide = (side, value, test) => (!side || (test ? value > side : value < side) ? value : side);
export const isCoursorInsideOneOfTheGroup = (x, y, widgetsGroups, sizeCoeff) => {
  return widgetsGroups.filter(({ coordinates }) => {
    const { top, left, right, bottom } = coordinates;
    const resizeHandlerSize = 15;
    return (
      x / (sizeCoeff / 100) >= left - resizeHandlerSize &&
      x / (sizeCoeff / 100) <= right + resizeHandlerSize &&
      y / (sizeCoeff / 100) >= top - resizeHandlerSize &&
      y / (sizeCoeff / 100) <= bottom + resizeHandlerSize
    );
  });
};
export const getGroupSides = widgets => {
  return widgets.reduce((acc, cur) => {
    acc.top = calculateSide(acc?.top, cur?.top);
    acc.left = calculateSide(acc?.left, cur?.left);
    acc.bottom = calculateSide(acc?.bottom, cur?.top + cur?.height, true);
    acc.right = calculateSide(acc?.right, cur?.left + cur?.width, true);
    return acc;
  }, {});
};

export const isIntersection = (
  { top: groupTop, left: groupLeft, right: groupRight, bottom: groupBottom },
  widgets,
  currentChosenGroup,
) => {
  if (!currentChosenGroup) {
    return false;
  }
  return widgets.some(widget => {
    if (widget.canvasGroupId !== currentChosenGroup) {
      const widgetBottom = widget.top + widget.height;
      const widgetRight = widget.left + widget.width;
      const top =
        groupTop <= widgetBottom &&
        groupTop >= widget.top &&
        groupBottom >= widgetBottom &&
        groupRight >= widget.left &&
        groupLeft <= widgetRight;
      const bottom =
        groupBottom >= widget.top &&
        groupBottom <= widgetBottom &&
        groupTop <= widget.top &&
        groupRight >= widget.left &&
        groupLeft <= widgetRight;
      const left =
        groupLeft <= widgetRight &&
        groupLeft >= widget.left &&
        groupRight >= widgetRight &&
        groupBottom >= widget.top &&
        groupTop <= widgetBottom;
      const right =
        groupRight >= widget.left &&
        groupRight <= widgetRight &&
        groupLeft <= widgetRight &&
        groupBottom >= widget.top &&
        groupTop <= widgetBottom;
      const insideGroup = widget.left >= groupLeft && widgetRight <= groupRight;
      return top || bottom || left || right || insideGroup;
    }

    return false;
  });
};

export const isActiveInputElement = () =>
  document.activeElement && document.activeElement.tagName.toLowerCase().includes('input');
