import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ResizableBox } from 'react-resizable';
import { useDraggable } from '@dnd-kit/core';
import classnames from 'classnames';

import { FontStyleEnum, getWidgetTranslate } from 'containers/Desktops/helpers';
import { HANDLE_SIZE, RESIZE_HANDLES, getMaxConstraints, widgetResize } from '../../helpers';
import { ResizeHandler } from '../../ResizeHandler/ResizeHandler';

import styles from './Fuel.module.css';

export const Fuel = props => {
  const { index } = props || {};
  const {
    indexSelect,
    watch,
    selectWidgetIndex,
    screensUpdate,
    checkWidget,
    selectedWidgets,
    sizeCoeff,
    currentChosenGroup,
    currentGroupWithIntersection,
    setDeltaTarget,
  } = useFormContext();
  const screens = watch('screens');
  const screen = screens[indexSelect] || {};
  const widgets = screens[indexSelect]?.widgets || [];
  const widget = widgets[index] || {};

  const elementId = index + screen.id;
  const { top, left, width, height, backgroundColor, squareForm, fuels } = widget;
  const [disabledDrag, setDisabledDrag] = useState(false);
  const { clientWidth, clientHeight } = document.getElementById('editor-droppable') || {};
  const onResize = (event, { size: newSize, handle }) => {
    const newWidgets = widgets.map((w, index) => {
      if (index === selectWidgetIndex) {
        const newParams = widgetResize({ handle, prev: w, size: newSize, newSize, clientWidth, clientHeight });
        const { top: y, left: x } = newParams;
        //Изменяем дельту для отрисовки направляющих линий
        setDeltaTarget({ x, y, resize: true });
        return { ...w, ...newParams };
      } else return w;
    });
    screensUpdate(indexSelect, { ...screen, widgets: newWidgets });
  };
  const setWidgetSize = (event, { size: newSize }, squareForm = false) => {
    const { width, height } = newSize;
    const newWidgets = widgets.map((w, index) => {
      if (index === selectWidgetIndex) {
        return { ...w, width, height, squareForm };
      } else return w;
    });
    screensUpdate(indexSelect, { ...screen, widgets: newWidgets });
  };

  useEffect(() => {
    if (squareForm) {
      const largeSide = width > height ? width : height;
      const newSize = { size: { width: largeSide, height: largeSide } };
      onResize(null, newSize);
      setWidgetSize(null, newSize, true);
    }
  }, [squareForm]);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: elementId,
    disabled: widget?.blockedOnCanvas || disabledDrag,
    data: { index },
  });

  const style = {
    top,
    left,
    backgroundColor,
    width: `${width}px`,
    height: `${height}px`,
    zIndex: index + 1,
  };
  const position = transform ? getWidgetTranslate(transform?.x, transform?.y, sizeCoeff) : undefined;
  const maxConstraints = getMaxConstraints(width, left, height, top, clientHeight, clientWidth);

  const getItemStyle = (column, item) => ({
    fontFamily: item[column + 'Font'],
    fontSize: `${item[column + 'FontSize']}px`,
    ...FontStyleEnum[item[column + 'FontStyle']],
  });

  return (
    <div
      id={elementId}
      ref={setNodeRef}
      style={{ ...position, ...style }}
      className={classnames(styles['wrapper'], {
        [styles['active']]: index === selectWidgetIndex && !widget?.blockedOnCanvas,
        [styles['checked']]: selectedWidgets[index] || widget.canvasGroupId,
        [styles['isInsideCurrentChosenGroup']]: widget.canvasGroupId && widget.canvasGroupId === currentChosenGroup,
        [styles['isInGroupWithIntersection']]:
          widget.canvasGroupId && widget.canvasGroupId === currentGroupWithIntersection,
      })}
      {...listeners}
      {...attributes}
    >
      <ResizableBox
        width={width}
        height={height}
        handleSize={[HANDLE_SIZE, HANDLE_SIZE]}
        handle={<ResizeHandler active={index === selectWidgetIndex} setDisabledDrag={setDisabledDrag} />}
        resizeHandles={RESIZE_HANDLES}
        onResize={onResize}
        onMouseUp={e => {
          e.ctrlKey && checkWidget(index);
          //Изменяем дельту для отрисовки направляющих линий
          setDeltaTarget(false);
        }}
        maxConstraints={maxConstraints}
      >
        <div className={styles['list']}>
          {['type', 'cost'].map(column => (
            <div key={elementId + column} className={styles['column']}>
              {fuels.map(item => (
                <div key={elementId + item.type + column} style={getItemStyle(column, item)}>
                  {item[column]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </ResizableBox>
    </div>
  );
};
