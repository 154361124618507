import PropTypes from 'prop-types';
import { useStateContext } from 'store';
import { useForm } from 'react-hook-form';
import { Loader } from 'containers/UI/Loader';
import { SelectForm } from 'containers/UI/Select';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useDevicesLoad } from 'containers/Hooks/devices/useDevicesLoad';

import styles from '../ModalSend.module.css';

export const ModalSend = ({ contentId = '', onSend = () => {} }) => {
  const { closePopup } = useStateContext();

  const { isLoading, devicesOptions, handleDevicesLoad } = useDevicesLoad();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const handleSend = form => {
    onSend({
      deviceId: form.devices,
      contentId,
    });
    closePopup();
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(handleSend)}>
        <div className={styles.deviceList}>
          {isLoading && <Loader />}
          {!isLoading && (
            <SelectForm
              name="devices"
              control={control}
              placeholder={'Устройство'}
              label={'Доступные устройства'}
              options={devicesOptions}
              searchable
              isMulti
              isInfinityLoad
              onLoad={handleDevicesLoad}
            />
          )}
        </div>
        <div className={styles.actions}>
          <Button type="submit" disabled={!isValid || isLoading}>
            Отправить
          </Button>
          <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
            Отмена
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalSend.propTypes = {
  contentId: PropTypes.string,
  onSend: PropTypes.func,
};
