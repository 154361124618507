import { Button } from 'containers/UI/Button';
import { useDesktopsModals } from '../hooks/useDesktopsModals';
import { Paginator } from 'containers/UI/Paginator/Paginator';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TemplateItem } from './TemplateItem';
import { Loader } from 'containers/UI/Loader';
import { Input } from 'containers/UI/Input';
import { useForm } from 'react-hook-form';
import { CREATE_NEW_TEMPLATE_MODE, MODAL_DESKTOP_TEMPLATES_PAGE_SIZE, SAVE_AS_TEMPLATE_MODE } from '../../helpers';

import styles from '../styles/DesktopsModals.module.css';

export const ModalDesktopTemplates = ({ closePopup = () => {}, append, setSelectScreen, screen, mode }) => {
  const {
    totalTemplatesCount,
    isLoading,
    screenTemplates,
    createNewDesktopFromTemplate,
    saveAsNewTemplate,
    changeExistingTemplate,
  } = useDesktopsModals();
  const [, setParams] = useSearchParams();
  const [chosenTemplateId, setChosenTemplateId] = useState(null);
  const { register, handleSubmit, watch, setValue } = useForm({ mode: 'all' });

  useEffect(() => {
    if (chosenTemplateId) {
      setValue('name', '');
    }
  }, [chosenTemplateId]);

  const renderTemplates = () => {
    return screenTemplates.map(({ id, name, width, height }) => {
      return (
        <TemplateItem
          key={id}
          id={id}
          name={name}
          width={width}
          height={height}
          setChosenTemplateId={setChosenTemplateId}
          chosenTemplateId={chosenTemplateId}
          previewTemplateMode={mode}
        />
      );
    });
  };

  const onFormSubmit = form => {
    const { name } = form;

    save(name);
  };

  const save = name => {
    if (mode === CREATE_NEW_TEMPLATE_MODE) {
      if (chosenTemplateId) {
        createNewDesktopFromTemplate(chosenTemplateId, append, setSelectScreen);
      }
    }

    if (mode === SAVE_AS_TEMPLATE_MODE) {
      if (!chosenTemplateId) {
        saveAsNewTemplate({ ...screen, name });
      }

      if (chosenTemplateId) {
        changeExistingTemplate({ chosenTemplateId, ...screen });
      }
    }

    closePopup();
  };

  useEffect(() => {
    return () => {
      setParams('');
    };
  }, []);

  return (
    <div className={styles['modal-wrapper']}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        {!isLoading && screen && (
          <div className={styles['template-new-name-wrapper']}>
            <div className={styles['text']}>Новый шаблон</div>
            <Input
              placeholder="Название"
              className={styles['new-template-name-input']}
              {...register('name')}
              disabled={chosenTemplateId !== null}
            />
          </div>
        )}

        {!isLoading && screen && <div className={styles['text']}>Сохранить в существующий</div>}
        <div className={styles[`templates-wrapper-${mode}-mode`]}>
          {isLoading && <Loader />}

          {!isLoading && renderTemplates()}
        </div>

        <div className={styles[`paginator-wrapper-${mode}-mode`]}>
          {totalTemplatesCount !== 0 && (
            <>
              <Paginator
                styleData={{ margin: 0 }}
                totalEntitiesCount={totalTemplatesCount}
                pageSize={MODAL_DESKTOP_TEMPLATES_PAGE_SIZE}
              />

              {!screen && (
                <Button className={styles['close-btn']} onClick={save}>
                  Закрыть
                </Button>
              )}

              {screen && (
                <div className={styles['buttons']}>
                  <Button className={styles['save-btn']} type="submit" disabled={!chosenTemplateId && !watch('name')}>
                    Сохранить
                  </Button>
                  <Button className={styles['close-btn']} onClick={closePopup}>
                    Отмена
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
};

ModalDesktopTemplates.propTypes = {
  closePopup: PropTypes.func,
  append: PropTypes.func,
  setSelectScreen: PropTypes.func,
  screen: PropTypes.object,
  mode: PropTypes.string,
};
