import PropTypes from 'prop-types';
import { Button, ButtonSizeEnum, ButtonVariantEnum } from 'containers/UI/Button';
import { DevicesMapControl } from '../DevicesMapControl/DevicesMapControl';

import styles from './DevicesMapControlButton.module.css';

export const DevicesMapControlButton = ({ position = 'topright', text, onClick }) => {
  return (
    <DevicesMapControl position={position}>
      <Button
        className={styles.deviceMapControlButton}
        variant={ButtonVariantEnum.PRIMARY}
        size={ButtonSizeEnum.SM}
        onClick={onClick}
      >
        {text}
      </Button>
    </DevicesMapControl>
  );
};

DevicesMapControlButton.propTypes = {
  text: PropTypes.string,
  position: PropTypes.string,
  onClick: PropTypes.func,
};
