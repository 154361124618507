import { useScheduleMain } from './useScheduleMain';
import styles from './ScheduleMain.module.css';
import { ScheduleDays } from '../ScheduleDays/ScheduleDays';
import { ScheduleTable } from '../ScheduleTable/ScheduleTable';

export const ScheduleMain = () => {
  const { handleScroll, toggleBodyScroll } = useScheduleMain();

  return (
    <div
      className={styles.scheduleMain}
      onWheel={handleScroll}
      onMouseEnter={() => toggleBodyScroll('hidden')}
      onMouseLeave={() => toggleBodyScroll('scroll')}
    >
      <ScheduleDays />
      <ScheduleTable />
    </div>
  );
};
