import PropTypes from 'prop-types';
import { useStateContext } from 'store';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { TextArea } from 'containers/UI/TextArea/TextArea';

import styles from './ModalHtmlCode.module.css';

export const ModalHtmlCode = ({ name = '', options = {}, register = () => ({}) }) => {
  const { closePopup } = useStateContext();

  return (
    <div className={styles['wrapper']}>
      <TextArea rows={35} register={register(name, options)} />
      <div className={styles['actions']}>
        <Button variant={ButtonVariantEnum.SOLID_SECONDARY} onClick={closePopup}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

ModalHtmlCode.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  register: PropTypes.func,
};
