import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useStateContext } from 'store';
import { emailSchema, passwordSchema } from 'helpers/validation';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { Input, InputActionTypeEnum, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';

import styles from './ModalAdminsRecovery.module.css';

export const ModalAdminsRecovery = ({ adminData = {} }) => {
  const { id, email } = adminData;
  const { closePopup, recoveryAdmin } = useStateContext();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { email },
  });

  const save = ({ email, password }) => {
    recoveryAdmin({ id, email, password }, closePopup);
  };

  const getPasswordSchema = fieldName => ({
    ...passwordSchema,
    validate: {
      ...passwordSchema.validate,
      retryPassword: val => {
        if (watch(fieldName) != val) return 'Ваши пароли не совпадают';
      },
    },
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit(save)}>
      <Input
        className={styles.form_field}
        label={'Email'}
        placeholder={'Email'}
        type={InputTypeEnum.EMAIL}
        error={InputParseErrors(errors.email, { minLength: 8, maxLength: 30 })}
        {...register('email', emailSchema)}
      />

      <Input
        className={styles.form_field}
        type={InputTypeEnum.PASSWORD}
        label={'Пароль'}
        placeholder={'Пароль'}
        actions={[InputActionTypeEnum.EYE, InputActionTypeEnum.DICE, InputActionTypeEnum.COPY]}
        error={InputParseErrors(errors.password, passwordSchema)}
        {...register('password', passwordSchema)}
      />

      <Input
        className={styles.form_field}
        type={InputTypeEnum.PASSWORD}
        label={'Пароль повторно'}
        placeholder={'Пароль повторно'}
        actions={[InputActionTypeEnum.EYE]}
        error={InputParseErrors(errors.confirm_password, getPasswordSchema('password'))}
        {...register('confirm_password', getPasswordSchema('password'))}
      />

      <div className={styles.form_actions}>
        <Button className={styles.form_button} type="submit" disabled={!isValid}>
          Восстановить
        </Button>
        <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
          Отмена
        </Button>
      </div>
    </form>
  );
};

ModalAdminsRecovery.propTypes = {
  adminData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
  }),
};
