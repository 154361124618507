import PropTypes from 'prop-types';
import { Loader } from 'containers/UI/Loader';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';

import styles from './DevicesMonitorCard.module.css';

export const DevicesMonitorCard = props => {
  const { name, created, error, image, loading, screenWidth, screenHeight, onRefresh } = props;

  return (
    <div className={styles.wrapper}>
      {loading && (
        <div className={styles.loader_wrapper}>
          <Loader size={50} />
        </div>
      )}

      {!image && <div className={styles.wrapper_empty} />}
      {image && (
        <span className={styles.img_link}>
          <img src={image} alt="Снимок" />
        </span>
      )}

      <h3>{name}</h3>
      <div className={styles.params}>
        {created && (
          <div>
            <label>Обновлено:</label>
            <span>{created.toLocaleString()}</span>
          </div>
        )}

        {screenWidth && screenHeight && (
          <div>
            <label>Разрешение:</label>
            <span dangerouslySetInnerHTML={{ __html: `${screenWidth} &times; ${screenHeight}` }} />
          </div>
        )}

        {error && (
          <div>
            <label>Ошибка:</label>
            <span>{error}</span>
          </div>
        )}
      </div>

      <Button
        variant={ButtonVariantEnum.SOLID_SECONDARY}
        disabled={loading}
        iconData={{ icon: <RefreshIcon /> }}
        onClick={onRefresh}
      >
        Обновить
      </Button>
    </div>
  );
};

DevicesMonitorCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  created: PropTypes.instanceOf(Date),
  screenWidth: PropTypes.number,
  screenHeight: PropTypes.number,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onRefresh: PropTypes.func,
};
