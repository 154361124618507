import { Table } from 'containers/UI/Table';
import PropTypes from 'prop-types';
import HeaderFilterGroups from '../App/HeaderFilterGroups/HeaderFilterGroups';
import { useOrganisations } from './hooks/useOrganisations';

const Organisations = () => {
  const {
    header,
    body,
    groupOptions,
    isOrganisationsLoading,
    totalOrganisationsCount,
    organisationsFilterType,
    handleFilterGroup,
    handleOpenGroupSettings,
  } = useOrganisations();

  return (
    <>
      <HeaderFilterGroups
        filterType={organisationsFilterType}
        groupOptions={groupOptions}
        handleFilterGroup={handleFilterGroup}
        handleOpenGroupSettings={handleOpenGroupSettings}
      />

      <Table
        heading={header}
        body={body}
        isLoading={isOrganisationsLoading}
        totalEntitiesCount={totalOrganisationsCount}
        idKey="organisation"
        emptyText={'Вы еще не добавили ни одной организации'}
      />
    </>
  );
};

Organisations.propTypes = { admins: PropTypes.array, isLoading: PropTypes.bool };

export default Organisations;
