import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import isEqual from 'lodash.isequal';

import { screenTemplatesService } from 'services';
import {
  NEW_DESKTOP,
  decodeForm,
  encodeForm,
  encodeWidget,
  getEditorSettings,
  LOCATION_PLAYLISTS,
  LOCATION_PRESENTATIONS,
  LOCATION_SCREEN_TEMPLATES,
  RESIZE_DIRECTION_PLUS,
  encodeCompareForm,
  LOCATION_TABLEAU_TEMPLATES,
  LOCATION_SPORT_EVENTS,
} from './helpers';
import classNames from 'classnames';
import { useStateContext } from 'store';
import { responseHandler } from 'store/helpers/responseHandler';
import { setLocalStorage } from 'helpers/localStorage';
import { Loader } from 'containers/UI/Loader';
import { ModalSelectLastSave } from 'containers/UI/Modal/Components/Desktops/ModalSelectLastSave';
import { ModalDesktopTemplates } from './Modals/Components/ModalDesktopTemplates';
import PropTypes from 'prop-types';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { DesktopForm } from './DesktopForm/DesktopForm';
import { DesktopList } from './DesktopList/DesktopList';
import { DesktopEditor } from './DesktopEditor/DesktopEditor';
import { ReactComponent as Arrow } from 'icons/arrows/arrow_left.svg';
import { ReactComponent as ArrowDown } from 'icons/arrows/arrow_down_solid.svg';
import { DESKTOP_SHOULD_BE_CHOSEN } from '../../helpers/constants/responseCodes';

const FROM_TEMPLATE_MODE = 'FROM_TEMPLATE';
const NEW_DESKTOP_MODE = 'NEW_DESKTOP';

import styles from './Desktops.module.css';

const Desktops = ({ previewTemplateMode = '', previewTemplateId = '' }) => {
  const navigate = useNavigate();
  const { itemId } = useParams();
  const { pathname } = useLocation();

  const { service, originPage, method, location, moduleEditor } = getEditorSettings(pathname);
  const { closePopup, updateScreenTemplatesWidgets, openPopup, allFilesForDesktops } = useStateContext();
  const dropdownRef = useRef(null);
  // FORM CONTROLS
  const methods = useForm({ mode: 'all', defaultValues: { screens: [] } });
  const { handleSubmit, control, setValue, watch, getValues } = methods;
  const { fields, append, move, update, remove } = useFieldArray({ control, name: 'screens' });

  // PLAYLISTS OR PRESENTATION
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const dataRef = useRef(data);
  // SCREENS
  const [loadingScreens, setLoadingScreens] = useState(false);
  const [selectScreen, setSelectScreen] = useState(false);
  const getIndexSelect = () => {
    return location === LOCATION_SCREEN_TEMPLATES || previewTemplateMode
      ? 0
      : fields.findIndex(d => d.ourId === selectScreen);
  };
  const indexSelect = getIndexSelect();
  const [sizeCoeff, setSizeCoeff] = useState(100);
  const [isSizeMessageShown, setIsSizeMessageShown] = useState(false);
  const [lastClickTimeOut, setLastClickTimeOut] = useState(null);

  // WIDGETS
  const [selectWidgetIndex, setSelectWidgetIndex] = useState(undefined);
  const [selectedWidgets, setSelectedWidgets] = useState({});
  const [currentChosenGroup, setCurrentChosenGroup] = useState(null);
  const [currentGroupWithIntersection, setCurrentGroupWithIntersection] = useState(null);
  const [dropDownClass, setDropDownClass] = useState(false);
  const debounceTimer = useRef(0);
  const isWaitDebounceUpdateCallback = useRef(false);
  const isOpenLastSaveRef = useRef(false);

  // Для направляющих линий GuideLines
  const [deltaTarget, setDeltaTarget] = useState(false);

  const isPresentationsOrPlaylistsOrTableauTemplatesOrSportEvents =
    [
      LOCATION_PRESENTATIONS,
      LOCATION_PLAYLISTS,
      LOCATION_TABLEAU_TEMPLATES,
      LOCATION_SPORT_EVENTS,
      LOCATION_SCREEN_TEMPLATES,
    ].includes(location) && !previewTemplateMode;
  const localStorageCopy = localStorage.getItem(
    isPresentationsOrPlaylistsOrTableauTemplatesOrSportEvents ? moduleEditor : 'screenTemplates',
  );
  const lastTimestamp = localStorage.getItem('screenTemplates_timestamp');

  const localStorageJSON = localStorageCopy ? JSON.parse(localStorageCopy) : null;
  const lastTimestampJSON = lastTimestamp ? JSON.parse(lastTimestamp) : null;

  const handleCloseLastSave = () => {
    isOpenLastSaveRef.current = false;
  };

  const isEqualWidgets = () => {
    const { screens } = getValues();
    const [fakeScreen] = screens;

    if (fakeScreen) {
      const encodedWidgets = fakeScreen.widgets.map(encodeWidget);
      const decodeLocalChanges = localStorageJSON && localStorageJSON[itemId] && localStorageJSON[itemId].widgets;

      return {
        encodedWidgets,
        isEqual: localStorageJSON && localStorageJSON[itemId] && isEqual(encodedWidgets, decodeLocalChanges),
      };
    }

    return {
      encodedWidgets: [],
      isEqual: true,
    };
  };

  const isEqualScreens = (defaultValue = true) => {
    const currentScreens = getValues().screens;
    const decodeLocalChanges =
      localStorageJSON && localStorageJSON[itemId] && decodeForm(localStorageJSON[itemId].screens);

    // Hack for isEqual id/timestamp
    const excludeCompareFieldsScreens = encodeCompareForm({ screens: decodeLocalChanges });
    const excludeCompareFieldsCurrentScreens = encodeCompareForm({ screens: currentScreens });

    if (decodeLocalChanges) {
      return isEqual(excludeCompareFieldsScreens, excludeCompareFieldsCurrentScreens);
    }

    return defaultValue;
  };

  const handleCompleteLastSave = (selectTimeStamp, isScreenTemplates = false) => {
    const currentTimestamp = isScreenTemplates ? lastTimestampJSON[itemId] : dataRef.current.timestamp || 0;
    isOpenLastSaveRef.current = false;

    if (selectTimeStamp > currentTimestamp) {
      if (localStorageJSON && localStorageJSON[itemId]) {
        if (isScreenTemplates) {
          const fakeScreens = [{ id: 'fakeScreenId', widgets: localStorageJSON[itemId].widgets }];
          setValue('screens', decodeForm(fakeScreens.map(s => ({ ...s, ourId: s.id }))));
        } else {
          const updateData = {
            ...dataRef.current,
            ...localStorageJSON[itemId],
          };
          setValue('screens', decodeForm(localStorageJSON[itemId].screens));
          setData(updateData);
          dataRef.current = updateData;
          setSelectScreen(false);
          setSelectScreen(updateData.screens[0].id);
        }
      }
    } else {
      if (isScreenTemplates) {
        const { screens } = getValues();
        const [fakeScreen] = screens;
        const encodedWidgets = fakeScreen.widgets.map(encodeWidget);

        setLocalStorage(moduleEditor, {
          [itemId]: {
            timestamp: lastTimestampJSON[itemId],
            widgets: encodedWidgets,
          },
        });
      } else {
        setLocalStorage(moduleEditor, {
          [itemId]: {
            timestamp: dataRef.current.timestamp,
            ...getValues(),
          },
        });
      }
    }
  };

  useEffect(() => {
    if (isPresentationsOrPlaylistsOrTableauTemplatesOrSportEvents) {
      setLoading(true);
      Promise.all([method(itemId)])
        .then(([{ status, data }]) => {
          if (status === 200 && !!data) {
            setData(data);
            dataRef.current = data;
          }
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (isPresentationsOrPlaylistsOrTableauTemplatesOrSportEvents) {
      if (data.id) {
        setLoadingScreens(true);
        service
          .getScreens(data.id)
          .then(({ status, data }) => {
            if (status === 200 && Array.isArray(data)) {
              const currentTimestamp = dataRef.current.timestamp || 0;
              setValue('screens', decodeForm(data.map(s => ({ ...s, ourId: s.id }))));

              setTimeout(() => {
                if (
                  localStorageJSON &&
                  localStorageJSON[itemId] &&
                  !isEqualScreens() &&
                  localStorageJSON[itemId].timestamp > currentTimestamp
                ) {
                  isOpenLastSaveRef.current = true;

                  openPopup(
                    () => (
                      <ModalSelectLastSave
                        timestamp={localStorageJSON[itemId].timestamp}
                        lastSaveTimestamp={currentTimestamp}
                        onComplete={value => handleCompleteLastSave(value)}
                        onClose={handleCloseLastSave}
                      />
                    ),
                    {
                      title: 'Выбор последнего сохранения',
                    },
                  );
                }
              }, 10);
            }
          })
          .finally(() => setLoadingScreens(false));
      }
    }
  }, [data.id]);

  useLayoutEffect(() => {
    if (location === LOCATION_SCREEN_TEMPLATES || previewTemplateMode) {
      setLoadingScreens(true);

      Promise.all([
        screenTemplatesService.getScreenData(previewTemplateId),
        screenTemplatesService.getAllowedScreenTemplatesWidgets(itemId || previewTemplateId),
      ])
        .then(response => {
          if (response[0].status === 200 && response[1].status === 200) {
            return {
              screenData: response[0].data,
              widgets: response[1].data.widgets,
            };
          }
        })
        .then(({ screenData, widgets }) => {
          if (Array.isArray(widgets)) {
            const fakeScreens = [{ ...screenData, id: 'fakeScreenId', widgets }];
            setValue('screens', decodeForm(fakeScreens.map(s => ({ ...s, ourId: s.id }))));

            if (!previewTemplateId) {
              setTimeout(() => {
                const { isEqual } = isEqualWidgets();
                if (
                  localStorageJSON &&
                  localStorageJSON[itemId] &&
                  !isEqual &&
                  localStorageJSON[itemId].timestamp > lastTimestampJSON[itemId]
                ) {
                  isOpenLastSaveRef.current = true;

                  openPopup(
                    () => (
                      <ModalSelectLastSave
                        timestamp={localStorageJSON[itemId].timestamp}
                        lastSaveTimestamp={lastTimestampJSON[itemId]}
                        onComplete={value => handleCompleteLastSave(value, true)}
                        onClose={handleCloseLastSave}
                      />
                    ),
                    {
                      title: 'Выбор последнего сохранения',
                    },
                  );
                }
              }, 10);
            }
          }
        })
        .finally(() => setLoadingScreens(false));
    }
  }, [itemId]);

  const openCloseDropdown = () => {
    setDropDownClass(prevState => !prevState);
  };

  const onOpenSaveAsTemplateModal = () => {
    const isDesktopNotChosen = indexSelect === -1;

    if (isDesktopNotChosen) {
      responseHandler(false, '', () => {}, DESKTOP_SHOULD_BE_CHOSEN);
      return;
    }
    openPopup(
      () => <ModalDesktopTemplates screen={watch(`screens[${indexSelect}]`)} closePopup={closePopup} mode={'save'} />,
      {
        title: 'Сохранить как шаблон',
      },
    );
  };

  const handleCreateNewDesktop = mode => {
    if (mode === FROM_TEMPLATE_MODE) {
      openPopup(
        () => (
          <ModalDesktopTemplates
            closePopup={closePopup}
            append={append}
            setSelectScreen={setSelectScreen}
            mode={'create'}
          />
        ),
        { title: 'Выбор шаблона' },
      );
    }

    if (mode === NEW_DESKTOP_MODE) {
      create();
    }

    setDropDownClass(false);
  };

  const create = () => {
    const ourId = Math.random().toString();
    append({ ourId, id: ourId, ...NEW_DESKTOP });
    setSelectScreen(ourId);
  };

  const save = form => {
    if (isPresentationsOrPlaylistsOrTableauTemplatesOrSportEvents) {
      service
        .addScreens(encodeForm(form), data.id || itemId)
        .then(({ data, status }) => {
          if ([200, 204].includes(status)) {
            responseHandler(true, 'Изменения успешно сохранены');
            setLocalStorage(moduleEditor, {
              [itemId]: {
                timestamp: data.timestamp,
                ...getValues(),
              },
            });
          } else {
            responseHandler(false, 'Не удалось сохранить изменения');
          }
        })
        .catch(() => responseHandler(false, 'Не удалось сохранить изменения'));
      setSelectScreen(false);
    }

    if (location === LOCATION_SCREEN_TEMPLATES || previewTemplateMode) {
      const { screens } = form;
      const [fakeScreen] = screens;
      const encodedWidgets = fakeScreen.widgets.map(encodeWidget);
      updateScreenTemplatesWidgets(itemId, { widgets: encodedWidgets }, closePopup);
    }
  };

  const onResize = direction => {
    setIsSizeMessageShown(true);

    if (lastClickTimeOut) {
      setLastClickTimeOut(prevState => {
        clearTimeout(prevState);
        return null;
      });
    }

    const timeOut = setTimeout(() => {
      setIsSizeMessageShown(false);
    }, 1500);

    setLastClickTimeOut(timeOut);

    setSizeCoeff(prevState => {
      const isDirectionPlus = direction === RESIZE_DIRECTION_PLUS;
      const conditionUpdate = isDirectionPlus ? prevState < 1000 : prevState > 10;

      if (conditionUpdate) {
        return isDirectionPlus ? prevState + 5 : prevState - 5;
      }

      return prevState;
    });
  };

  const handleChange = () => {
    handleUpdateLocalStorage();
  };

  useEffect(() => {
    watch(() => {
      if (!isWaitDebounceUpdateCallback.current) {
        handleUpdateLocalStorage();
        isWaitDebounceUpdateCallback.current = true;

        debounceTimer.current = window.setTimeout(() => {
          handleUpdateLocalStorage();
          isWaitDebounceUpdateCallback.current = false;
        }, 1000);
      }
    });
  }, [watch]);

  const handleUpdateLocalStorage = () => {
    if (isOpenLastSaveRef.current) {
      return;
    }

    if (location === LOCATION_SCREEN_TEMPLATES) {
      const { encodedWidgets, isEqual } = isEqualWidgets();

      if (!isEqual) {
        setLocalStorage(moduleEditor, {
          [itemId]: {
            timestamp: Date.now(),
            widgets: encodedWidgets,
          },
        });
      }
    } else {
      if (!isEqualScreens(false)) {
        const prepareScreens = getValues().screens.map(s => ({ ...s, ourId: s.id }));
        const prepareEncodeForm = encodeForm({ screens: prepareScreens }, true);

        setTimeout(() => {
          setLocalStorage(moduleEditor, {
            [itemId]: {
              timestamp: Date.now(),
              screens: prepareEncodeForm,
            },
          });
        }, 1);
      }
    }
  };

  if (loading) return <Loader />;

  return (
    <FormProvider
      {...{
        ...methods,
        data,
        screensMove: move,
        screensUpdate: update,
        screenRemove: remove,
        indexSelect,
        setSelectScreen,
        selectWidgetIndex,
        setSelectWidgetIndex,
        selectedWidgets,
        checkWidget: i => setSelectedWidgets({ ...selectedWidgets, [i]: !selectedWidgets[i] }),
        setSelectedWidgets,
        files: allFilesForDesktops,
        location,
        previewTemplateMode,
        onResize,
        sizeCoeff,
        setSizeCoeff,
        isSizeMessageShown,
        setCurrentChosenGroup,
        currentChosenGroup,
        currentGroupWithIntersection,
        setCurrentGroupWithIntersection,
        deltaTarget,
        setDeltaTarget,
      }}
    >
      <form className={styles.wrapper} onChange={handleChange} onSubmit={handleSubmit(save)}>
        {!previewTemplateMode && (
          <header className={styles.header}>
            <Button variant={ButtonVariantEnum.TEXT} onClick={() => navigate(originPage)}>
              <Arrow />
            </Button>
            <span className={styles.title}>{data.name}</span>
            <Button type="submit" className={styles['header-action']}>
              Сохранить изменения
            </Button>
            <Button
              className={styles['header-action']}
              variant={ButtonVariantEnum.SOLID_ACCENT}
              onClick={() => navigate(originPage)}
            >
              Отмена
            </Button>
          </header>
        )}
        {!previewTemplateMode && (
          <div className={styles.list}>
            <div className={styles['actions-list']}>
              {(location === LOCATION_PLAYLISTS || location === LOCATION_PRESENTATIONS) && (
                <>
                  <Button onClick={openCloseDropdown}>
                    Создать рабочий стол <ArrowDown className={styles['arrow-down-solid']} />
                  </Button>

                  <div
                    className={classNames({
                      [styles['show']]: dropDownClass,
                      [styles['hidden']]: !dropDownClass,
                    })}
                    ref={dropdownRef}
                  >
                    <div
                      onClick={() => handleCreateNewDesktop(FROM_TEMPLATE_MODE)}
                      className={styles['create-new-desktop']}
                    >
                      На основе шаблона
                    </div>
                    <div
                      onClick={() => handleCreateNewDesktop(NEW_DESKTOP_MODE)}
                      className={styles['create-new-desktop']}
                    >
                      Пустой
                    </div>
                  </div>
                </>
              )}

              {(location === LOCATION_TABLEAU_TEMPLATES || location === LOCATION_SPORT_EVENTS) && (
                <Button onClick={() => handleCreateNewDesktop(NEW_DESKTOP_MODE)}>Создать рабочий стол</Button>
              )}
            </div>

            <div
              className={classNames({
                [styles['desktop-list-wrapper']]: [
                  LOCATION_PLAYLISTS,
                  LOCATION_PRESENTATIONS,
                  LOCATION_TABLEAU_TEMPLATES,
                  LOCATION_SPORT_EVENTS,
                ].includes(location),
              })}
            >
              <DesktopList loading={loadingScreens} />
            </div>

            {(location === LOCATION_PRESENTATIONS || location === LOCATION_PLAYLISTS) && !previewTemplateId && (
              <Button
                className={classNames(styles['header-action'], styles['create-new-template'])}
                variant={ButtonVariantEnum.SOLID_ACCENT}
                onClick={onOpenSaveAsTemplateModal}
              >
                Сохранить как шаблон
              </Button>
            )}
          </div>
        )}

        <DesktopEditor onUpdateLocalStorage={handleUpdateLocalStorage} />

        {!previewTemplateMode && (
          <div className={styles.params}>
            <DesktopForm />
          </div>
        )}
      </form>
    </FormProvider>
  );
};

Desktops.propTypes = {
  previewTemplateMode: PropTypes.string,
  previewTemplateId: PropTypes.string,
};

export default Desktops;
