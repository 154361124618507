import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { useCallback, useEffect, useState } from 'react';
import parseSeconds from 'helpers/date/parseSeconds';
import { hmsToSeconds } from 'helpers/time/hmsToSeconds';
import { TimePickerForm } from 'containers/UI/TimePicker';
import { SelectForm } from 'containers/UI/Select';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { usePlaylistsLoad } from 'containers/Hooks/playlists/usePlaylistsLoad';
import { isEmpty } from 'helpers/lodash';

import styles from '../ModalSchedulePlaylistAdd/ModalSchedulePlaylist.module.css';

const DEFAULT_VALUES = {
  start: '00:00:00',
  end: '23:59:59',
  duration: '23:59:59',
};

export const ModalSchedulePlaylistEdit = props => {
  const { schedulePlaylist = null, handleUpdate, existingPlaylists } = props;
  const [isValidTime, setIsValidTime] = useState(true);
  const [durationState, setDurationState] = useState('00:00:00');
  const [timeAlreadyTakenMessage, setTimeAlreadyTakenMessage] = useState('');

  const { closePopup } = useStateContext();

  const { playlistOptions, handlePlaylistsLoad } = usePlaylistsLoad();

  let defaultValues = DEFAULT_VALUES;

  const initFormValues = function () {
    const { playlistId, start, end, timeFrom, timeTo } = schedulePlaylist;
    const { hours, minutes, seconds } = parseSeconds(end - start, true);
    const duration = `${hours}:${minutes}:${seconds}`;

    defaultValues = {
      playlistId,
      start: timeFrom,
      end: timeTo,
      duration,
    };
  };
  initFormValues();

  const { watch, control, handleSubmit, getValues, setValue } = useForm({ mode: 'onChange', defaultValues });
  const { start, end, duration } = getValues();

  const save = async form => {
    const updatedPlaylist = {
      ...form,
      timeFrom: start,
      timeTo: end,
      start: hmsToSeconds(start),
      end: hmsToSeconds(end),
      id: schedulePlaylist.id,
      name: schedulePlaylist.name,
      comment: schedulePlaylist.comment,
      weekday: schedulePlaylist.weekday,
    };

    handleUpdate(updatedPlaylist);
    closePopup();
  };

  const durationSetter = function () {
    useEffect(() => {
      const startSec = hmsToSeconds(start);
      const endSec = hmsToSeconds(end);
      const { hours, minutes, seconds } = parseSeconds(endSec - startSec, true);
      const duration = `${hours}:${minutes}:${seconds}`;
      const isTimeTaken = isTimeAlreadyTaken(schedulePlaylist.id, startSec, endSec);

      setValue('duration', !duration.includes('-') ? duration : '00:00:00');
      setDurationState(`${hours}:${minutes}:${seconds}`);

      if (isTimeTaken) {
        setTimeAlreadyTakenMessage('Данное время занято другим плейлистом. Пожалуйста выберите другое');
      } else {
        setTimeAlreadyTakenMessage('');
      }

      setIsValidTime(startSec < endSec || isTimeTaken);
    }, [watch('start'), watch('end')]);
  };
  durationSetter();

  const endTimeByDurationChangeSetter = function () {
    useEffect(() => {
      if (durationState !== duration && duration) {
        const startTimeInSeconds = hmsToSeconds(start);
        const durationInSeconds = hmsToSeconds(duration);
        const endTimeInSeconds = startTimeInSeconds + durationInSeconds;
        const { hours, minutes, seconds } = parseSeconds(endTimeInSeconds, true);

        setValue('end', `${hours}:${minutes}:${seconds}`);
      }
    }, [watch('duration')]);
  };
  endTimeByDurationChangeSetter();

  const isTimeAlreadyTaken = useCallback(
    (id, start, end) => {
      if (!isEmpty(existingPlaylists)) {
        for (const existingPlaylist of existingPlaylists) {
          if (existingPlaylist.id === id) {
            return;
          }
          if (start > existingPlaylist.start && end < existingPlaylist.end) {
            return true;
          }

          if (start < existingPlaylist.end && end < existingPlaylist.end && end > existingPlaylist.start) {
            return true;
          }
        }
      }
      setTimeAlreadyTakenMessage('');

      return false;
    },
    [existingPlaylists],
  );

  const buttonDisabled = !isValidTime || !watch('playlistId');

  return (
    <form className={styles.form} onSubmit={handleSubmit(save)}>
      <SelectForm
        name="playlistId"
        control={control}
        placeholder={'Плейлист'}
        label={'Плейлист'}
        options={playlistOptions}
        searchable
        isInfinityLoad
        onLoad={handlePlaylistsLoad}
      />

      <TimePickerForm
        name="start"
        control={control}
        valid={isValidTime}
        label={'Время начала воспроизведения плейлиста'}
      />

      <TimePickerForm
        name="end"
        control={control}
        valid={isValidTime}
        label={'Время завершения воспроизведения плейлиста'}
      />

      <TimePickerForm name="duration" control={control} label={'Длительность воспроизведения плейлиста'} />

      {timeAlreadyTakenMessage && <div className={styles.timeIsTaken}>{timeAlreadyTakenMessage}</div>}

      <div className={styles.actions}>
        <Button type="submit" disabled={buttonDisabled}>
          Сохранить
        </Button>
        <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
          Отмена
        </Button>
      </div>
    </form>
  );
};

ModalSchedulePlaylistEdit.propTypes = {
  schedulePlaylist: PropTypes.object,
  handleUpdate: PropTypes.func,
  existingPlaylists: PropTypes.array,
};
