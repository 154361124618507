import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { Checkbox } from 'containers/UI/Checkbox';
import { SelectForm } from 'containers/UI/Select';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { PermissionTitle } from 'helpers/constants/permissionsConstants';
import { useEffect, useMemo } from 'react';
import { isEmpty } from 'helpers/lodash';

import styles from './ModalAdminsEdit.module.css';

export const ModalAdminsEdit = ({ adminData = {} }) => {
  const { id, email } = adminData;
  const {
    closePopup,
    updateAdmin,
    getAdminById,
    fullAdminData,
    allowedWidgets,
    getAllowedWidgets,
    setAllowedWidgets,
    setFullAdminData,
    setIsModalInAdminsOpen,
    isModalInAdminsOpen,
    allOrganisationsListInAdmins,
  } = useStateContext();

  useEffect(() => {
    if (getAllowedWidgets.length === 0) {
      getAllowedWidgets(true);
    }

    getAdminById(id);

    if (!isModalInAdminsOpen) {
      setIsModalInAdminsOpen(true);
    }

    return () => {
      setFullAdminData({});
      setAllowedWidgets([]);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(fullAdminData)) {
      reset({
        widgets: fullAdminData?.widgets || [],
        mobileAppActiveScreenAvailability: fullAdminData.permissions.mobileAppActiveScreenAvailability,
        sportEventsAvailability: fullAdminData.permissions.sportEventsAvailability,
        clientId: fullAdminData?.client?.id,
      });
    }
  }, [fullAdminData]);

  const organisationsOptions = useMemo(() => {
    const options = allOrganisationsListInAdmins.map(organisation => ({
      name: organisation.name,
      value: organisation.id,
    }));

    return [{ name: 'Без организации', value: 'null' }, ...options];
  }, [allOrganisationsListInAdmins]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...fullAdminData,
      widgets: fullAdminData?.widgets?.filter(widget => !widget.includes('_')),
    },
  });

  const save = form => {
    const formData = {
      widgets: form.widgets,
      permissions: {
        mobileAppActiveScreenAvailability: form.mobileAppActiveScreenAvailability,
        sportEventsAvailability: form.sportEventsAvailability,
      },
      clientId: form.clientId !== 'null' ? form.clientId : null,
    };

    updateAdmin({ id, ...formData }, closePopup);
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_email_label}>{email}</div>

        <div className={styles.form_fields}>
          <SelectForm
            name="widgets"
            control={control}
            placeholder={'Выберите виджеты'}
            label={'Доступные выджеты'}
            options={allowedWidgets}
            searchable
            isMulti
          />

          <SelectForm
            name="clientId"
            control={control}
            placeholder={'Название организации'}
            label={'Организация'}
            options={organisationsOptions}
            searchable
            rules={{ required: false }}
          />

          <Checkbox
            key={PermissionTitle.mobileAppActiveScreenAvailability}
            label={PermissionTitle.mobileAppActiveScreenAvailability}
            control={control}
            name="mobileAppActiveScreenAvailability"
          />

          <Checkbox
            key={PermissionTitle.sportEventsAvailability}
            label={PermissionTitle.sportEventsAvailability}
            control={control}
            name="sportEventsAvailability"
          />
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={!isValid}>
            Сохранить
          </Button>
          <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
            Отмена
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalAdminsEdit.propTypes = {
  adminData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    widgets: PropTypes.array,
    permissions: PropTypes.shape({
      mobileAppActiveScreenAvailability: PropTypes.bool,
      sportEventsAvailability: PropTypes.bool,
    }),
  }),
};
