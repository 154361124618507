import { useStateContext } from 'store';
import { useCallback } from 'react';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ModalTerminalUpdate } from 'containers/UI/Modal/Components/TerminalUpdates/ModalTerminalUpdate';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useTerminalUpdates = () => {
  const { openPopup, terminalUpdates, isTerminalUpdatesLoading, totalTerminalUpdatesCount, deleteTerminalUpdate } =
    useStateContext();

  const handleCreate = useCallback(() => {
    openPopup(() => <ModalTerminalUpdate />, { title: 'Создание обновления терминала' });
  }, []);

  const handleChangeTaskStatus = useCallback(terminalUpdate => {
    openPopup(() => <ModalTerminalUpdate terminalUpdate={terminalUpdate} />, { title: 'Изменение статуса задания' });
  }, []);

  const handleDeleteTerminalUpdate = useCallback(terminalUpdateId => {
    openPopup(
      () => (
        <ModalDelete
          contentId={terminalUpdateId}
          title={'Вы уверены что хотите удалить обновление терминала?'}
          isVisibleIcon={false}
          onDelete={deleteTerminalUpdate}
        />
      ),
      {
        title: 'Удаление обновления терминала',
      },
    );
  }, []);

  const header = [
    { key: 'releaseName', title: 'Название релиза (Номер версии)' },
    { key: 'os', title: 'Операционная система' },
    { key: 'updatePeriod', title: 'Период времени обновления' },
    { key: 'status', title: 'Статус' },
    {
      key: 'actions',
      title: (
        <div key="terminalUpdates-head-button" className={tableStyles.rowButtons}>
          <Button key="terminalUpdate-create" className={tableStyles.headerButton} onClick={handleCreate}>
            Создать обновление терминала
          </Button>
        </div>
      ),
    },
  ];

  const body = terminalUpdates?.map(terminalUpdate => {
    const { release, timeFrom, timeTo, state } = terminalUpdate || {};
    return {
      releaseName: release.name,
      updatePeriod: `${timeFrom} - ${timeTo}`,
      status: state[0].toUpperCase() + state.slice(1),
      actions: (
        <div key="terminalUpdate-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить статус задания'}
            onClick={() => handleChangeTaskStatus(terminalUpdate)}
          />

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить обновление терминала'}
            onClick={() => handleDeleteTerminalUpdate(terminalUpdate.id)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,
    isTerminalUpdatesLoading,
    totalTerminalUpdatesCount,
  };
};
