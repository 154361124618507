import PropTypes from 'prop-types';
import parseSeconds from 'helpers/date/parseSeconds';

import styles from './ModalSchedulePlaylistInfo.module.css';

export const ModalSchedulePlaylistInfo = ({ playlist = {} }) => {
  const { name, comment, timeFrom, timeTo, start, end } = playlist;

  const { hours, minutes, seconds } = parseSeconds(end - start, true);

  return (
    <div className={styles.playlistInfoWrapper}>
      <div className={styles.playlistInfoItem}>
        <div className={styles.playlistInfoItem__title}>Плейлист</div>
        <div>{name}</div>
      </div>
      <div className={styles.playlistInfoItem}>
        <div className={styles.playlistInfoItem__title}>Комментарий</div>
        <div>{comment}</div>
      </div>
      <div className={styles.playlistInfoItemTime}>
        <div className={styles.playlistInfoItem}>
          <div className={styles.playlistInfoItem__title}>Время начала воспроизведения</div>
          <div>{timeFrom}</div>
        </div>
        <div className={styles.playlistInfoItem}>
          <div className={styles.playlistInfoItem__title}>Время завершения воспроизведения</div>
          <div>{timeTo}</div>
        </div>
      </div>
      <div className={styles.playlistInfoItem}>
        <div className={styles.playlistInfoItem__title}>Длительность воспроизведения</div>
        <div>{`${hours}:${minutes}:${seconds}`}</div>
      </div>
    </div>
  );
};

ModalSchedulePlaylistInfo.propTypes = { playlist: PropTypes.object };
