import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useScheduleTablePlaylist } from './useScheduleTablePlaylist';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Close } from 'icons/close.svg';
import { ReactComponent as Info } from 'icons/schedule/info.svg';
import { ReactComponent as Delete } from 'icons/schedule/delete.svg';

import cssStyles from './ScheduleTablePlaylist.module.css';

export const ScheduleTablePlaylist = ({ playlist, weekday, isLast, setResize, stopResize }) => {
  const {
    width,
    styles,
    timeFormat,
    setIsDragging,
    handleAdd,
    handleInfo,
    handleEdit,
    handleDelete,
    createPlaylist,
    deleteWeekdayPlaylist,
  } = useScheduleTablePlaylist(playlist);

  const startResize = (e, direction) => {
    e.stopPropagation();
    const { clientX, clientY } = e;

    setResize({
      enabled: true,
      left: clientX,
      top: clientY,
      direction,
      playlist,
      weekday,
    });
  };

  const handleStopResize = () => {
    stopResize();
  };

  function handleWindowMouseUp(e) {
    if (e.target.dataset.day) {
      const weekdayDragging = e.target.dataset.day;

      deleteWeekdayPlaylist(playlist.id, playlist.weekday);

      setTimeout(() => {
        createPlaylist({
          playlistId: playlist.playlistId,
          weekday: weekdayDragging,
          name: playlist.name,
          start: playlist.start,
          end: playlist.end,
          timeFrom: playlist.timeFrom,
          timeTo: playlist.timeTo,
        });
      }, 1);
    }

    unmountEvents();
    document.body.style.cursor = '';
    setIsDragging(false);
  }

  const handleMouseDown = () => {
    setIsDragging(true);
    document.body.style.cursor = 'grab';
    window.addEventListener('mouseup', handleWindowMouseUp);
  };

  const unmountEvents = () => {
    window.removeEventListener('mouseup', handleWindowMouseUp);
  };

  return (
    <div
      className={classnames(cssStyles.schedulePlaylist, {
        [cssStyles.schedulePlaylist__medium]: width < 330,
        [cssStyles.schedulePlaylist__small]: width < 180,
      })}
      style={styles}
      onMouseDown={handleMouseDown}
      onMouseUp={handleStopResize}
    >
      <div
        className={classnames(cssStyles.schedulePlaylist_Stick, cssStyles.schedulePlaylist_Stick_left)}
        onMouseDown={e => startResize(e, 'left')}
        onDragStart={e => e?.stopImmediatePropagation()}
      />
      <div className={cssStyles.schedulePlaylist__Body}>
        <div className={cssStyles.schedulePlaylist__BodyWrapper}>
          <span className={cssStyles.schedulePlaylist__Name}>{playlist.name}</span>
          <span className={cssStyles.schedulePlaylist__Time}>({timeFormat})</span>
        </div>
        <div className={cssStyles.schedulePlaylist__Actions}>
          <div className={cssStyles.schedulePlaylist__Button}>
            <Button
              className={cssStyles.schedulePlaylist__Icon}
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <Info /> }}
              title={'Информация'}
              onClick={handleInfo}
            />
          </div>
          <div className={cssStyles.schedulePlaylist__Button}>
            <Button
              className={cssStyles.schedulePlaylist__Icon}
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <Edit />, inlineStyles: { width: '16px', height: '16px' } }}
              title={'Изменить'}
              onClick={() => handleEdit(weekday)}
            />
          </div>
          <div className={cssStyles.schedulePlaylist__Button}>
            <Button
              className={cssStyles.schedulePlaylist__Icon}
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <Delete /> }}
              title={'Удалить'}
              onClick={() => handleDelete()}
            />
          </div>
        </div>
      </div>
      <div
        className={classnames(cssStyles.schedulePlaylist_Stick, cssStyles.schedulePlaylist_Stick_right)}
        onMouseDown={e => startResize(e, 'right')}
        onDragStart={e => e.stopImmediatePropagation()}
      />
      {isLast && (
        <Button
          className={cssStyles.schedulePlaylist__AddButton}
          variant={ButtonVariantEnum.ICON}
          iconData={{ icon: <Close />, inlineStyles: { width: '12px', height: '12px', transform: 'rotate(45deg)' } }}
          title={'Добавить плейлист'}
          onClick={() => handleAdd(weekday)}
        />
      )}
    </div>
  );
};

ScheduleTablePlaylist.propTypes = {
  playlist: PropTypes.object,
  weekday: PropTypes.string,
  isLast: PropTypes.bool,
  resizeInfo: PropTypes.number,
  setResize: PropTypes.func,
  stopResize: PropTypes.func,
};
