import { useStateContext } from 'store';
import { useCallback, useMemo } from 'react';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ModalTerminalReleasesAdd, ModalTerminalReleasesEdit } from 'containers/UI/Modal/Components/TerminalReleases';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from '../TerminalReleases.module.css';
import tableStyles from 'containers/UI/Table/Table.module.css';

export const useTerminalReleases = () => {
  const { terminalReleases, openPopup, isTerminalReleasesLoading, totalTerminalReleasesCount, removeTerminalRelease } =
    useStateContext();

  const handleTerminalReleasesAdd = useCallback(() => {
    openPopup(() => <ModalTerminalReleasesAdd />, {
      title: 'Создание релиза терминала',
    });
  }, []);

  const handleTerminalReleasesDelete = useCallback(terminalRelease => {
    openPopup(
      () => (
        <ModalDelete
          contentId={terminalRelease.id}
          title={`Вы уверены что хотите удалить ${terminalRelease.name}?`}
          isVisibleIcon={false}
          onDelete={removeTerminalRelease}
        />
      ),
      {
        title: 'Удаление релиза терминала',
      },
    );
  }, []);

  const handleTerminalReleasesEdit = useCallback(terminalRelease => {
    openPopup(() => <ModalTerminalReleasesEdit terminalRelease={terminalRelease} />, {
      title: 'Редактирование релиза терминала',
    });
  }, []);

  const header = [
    { key: 'name', title: 'Название (версия)' },
    { key: 'os', title: 'Операционная система' },
    { key: 'fileName', title: 'Имя файла' },
    { key: 'comment', title: 'Комментарий (релиз ноутс)' },
    {
      key: 'actions',
      title: (
        <div key="terminal-release-head-button" className={tableStyles.rowButtons}>
          <Button
            key="button-create-terminal-release"
            onClick={handleTerminalReleasesAdd}
            className={tableStyles.headerButton}
          >
            Создать релиз
          </Button>
        </div>
      ),
    },
  ];

  const body = useMemo(
    () =>
      terminalReleases?.map(terminalRelease => {
        const { name, fileName, comment } = terminalRelease;

        return {
          name: (
            <div key="terminal-release-item-name" className={styles.terminalReleasesItemEllipsis}>
              {name}
            </div>
          ),
          fileName,
          comment: (
            <div key="terminal-release-item-comment" className={styles.terminalReleasesItemEllipsis}>
              {comment}
            </div>
          ),
          actions: (
            <div key="terminal-release-item-buttons" className={tableStyles.rowButtons}>
              <Button
                key="terminal-release-action-edit"
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <Edit /> }}
                title={'Изменить'}
                onClick={() => handleTerminalReleasesEdit(terminalRelease)}
              />
              <Button
                key="terminal-release-action-delete"
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <Delete /> }}
                title={'Удалить'}
                onClick={() => handleTerminalReleasesDelete(terminalRelease)}
              />
            </div>
          ),
        };
      }),
    [terminalReleases],
  );

  return {
    header,
    body,
    isTerminalReleasesLoading,
    totalTerminalReleasesCount,
  };
};
