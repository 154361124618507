import { useCallback, useEffect } from 'react';
import { presentationsService } from 'services';
import { responseHandler } from '../helpers/responseHandler';
import { useApi } from 'services/hooks/useApi';
import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';

export const usePresentationsState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [
    presentations,
    setPresentations,
    isPresentationsLoading,
    presentationsError,
    setPresentationsError,
    totalPresentationsCount,
    setTotalPresentationsCount,
  ] = useApi(() => presentationsService.getPresentations(getQueryParams(+page - 1) || {}));
  const [filterType, setFilterType] = useState(null);

  const [presentationsScreens, setPresentationsScreens] = useState([]);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.presentations) {
        return (() => {
          presentationsService
            .getPresentations(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setPresentations(response.data.items);
              }
            })
            .catch(setPresentationsError);
        })();
      }
    }, [pathname, page, totalPresentationsCount]);
  };
  paginate();

  const addPresentation = useCallback((newPresentation, onModalClose) => {
    return (() => {
      presentationsService
        .addPresentation(newPresentation)
        .then(result => {
          if (result.status === 200) {
            setTotalPresentationsCount(prevState => prevState + 1);
            responseHandler(true, 'Презентация была успешно создана', onModalClose);
          }
        })
        .catch(e => {
          setPresentationsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  }, []);

  const copyPresentationWithScreens = (newPresentation, onModalClose) => {
    return (() => {
      presentationsService
        .addPresentation(newPresentation)
        .then(response => {
          if (response.status === 200) {
            const newPresentationId = response?.data?.id;
            presentationsService
              .addScreens(presentationsScreens, newPresentationId)
              .then(response => {
                if (response.status === 204) {
                  setTotalPresentationsCount(prevState => prevState + 1);
                  responseHandler(true, 'Презентация была успешно скопирована', onModalClose);
                }
              })
              .catch(error => {
                setPresentationsError(error);
                responseHandler(false, '', onModalClose, error.response.data.code);
              });
          }
        })
        .catch(e => {
          setPresentationsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  };

  const getScreens = useCallback(presentationId => {
    presentationsService
      .getScreens(presentationId)
      .then(response => {
        if (response.status === 200) {
          response?.data && setPresentationsScreens(response.data);
        }
      })
      .catch(setPresentationsError);
  }, []);

  const editPresentation = useCallback((scheduleId, updateData, onModalClose) => {
    return (() => {
      presentationsService
        .updatePresentation(scheduleId, updateData)
        .then(result => {
          if (result.status === 204) {
            setPresentations(prev => [
              ...prev.map(el => {
                if (el.id === updateData.id) return { ...el, ...updateData };
                return el;
              }),
            ]);
            responseHandler(true, 'Презентация была успешна изменена', onModalClose);
          }
        })
        .catch(e => {
          setPresentationsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  }, []);

  const deletePresentation = useCallback((presentationId, onModalClose) => {
    return (() => {
      presentationsService
        .deletePresentation(presentationId)
        .then(result => {
          if (result.status === 204) {
            setTotalPresentationsCount(prevState => prevState - 1);
            setPresentations(prev => prev.filter(el => el.id !== presentationId));
            responseHandler(true, 'Презентация была успешна удалена', onModalClose);
          }
        })
        .catch(e => {
          setPresentationsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  }, []);

  return {
    addPresentation,
    editPresentation,
    deletePresentation,
    presentations,
    isPresentationsLoading,
    presentationsError,
    filterType,
    setFilterType,
    totalPresentationsCount,
    copyPresentationWithScreens,
    getScreens,
    setPresentationsScreens,
  };
};
