import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useParams } from 'react-router-dom';
import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { isEmpty } from '../../helpers/lodash';

import { useStateContext } from 'store';
import { responseHandler } from 'store/helpers/responseHandler';
import { CHART_DATE_PERIOD } from 'helpers/constants/responseCodes';
import { DatePicker } from 'containers/UI/DatePicker';
import { SelectForm } from 'containers/UI/Select';
import { Button } from 'containers/UI/Button';
import { ReactComponent as BackArrow } from 'icons/backArrow.svg';

import styles from './DeviceChart.module.css';
import classNames from 'classnames';
// import { GRAPHIC_API_MOCK_DATA, SENSORS_LIST_MOCK_DATA } from './DeviceChartMockData';

const StyledTooltip = ({ payload, label }) => {
  if (!isEmpty(payload)) {
    return (
      <div className={styles['tooltip-wrapper']}>
        <div>Дата/Время</div>
        <div>{label}</div>
        <div>Показания</div>
        <div>{payload[0]?.value}</div>
      </div>
    );
  }
};

const StyledXAxis = ({ x, y, payload }) => {
  const renderSpans = () => {
    const dateTime = payload.value.split('\n');
    const isDateAndTime = dateTime.length === 2;

    if (isDateAndTime) {
      const date = dateTime[0];
      const time = dateTime[1];

      return (
        <>
          <tspan x={x} y={y - 15}>
            {date}
          </tspan>

          <tspan x={x} y={y + 44}>
            {time}
          </tspan>
        </>
      );
    }

    if (!isDateAndTime) {
      return (
        <tspan x={x} y={y}>
          {payload.value}
        </tspan>
      );
    }
  };

  return (
    <text dy={44} textAnchor="middle" fill="#fff">
      {renderSpans()}
    </text>
  );
};

export const DevicesChart = () => {
  const { deviceId } = useParams();
  const { getSensorsList, sensorsList, getSensorValues, sensorValues } = useStateContext();

  // TEST
  // const sensorsList = SENSORS_LIST_MOCK_DATA
  // const sensorValues = GRAPHIC_API_MOCK_DATA
  // TEST

  const { control, handleSubmit, watch } = useForm({ mode: 'all' });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    getSensorsList(deviceId);
  }, []);

  const handleFilterApply = () => {
    const sensorID = watch('sensorID');
    const datesParams = { dateFrom: fromDate?.toISOString(), dateTo: toDate?.toISOString() };

    getSensorValues(sensorID, datesParams);
  };

  const getSensorsOptions = () => {
    return sensorsList.map(sensor => {
      return { name: sensor.sensor, value: sensor.id };
    });
  };

  const renderBackArrow = () => {
    return (
      <NavLink className={styles['back-arrow-wrapper']} to={'/devices'}>
        <BackArrow /> Назад
      </NavLink>
    );
  };

  const dateHandler = (date, isFrom) => {
    const method = isFrom ? setFromDate : setToDate;
    const otherDate = isFrom ? toDate : fromDate;
    if (date && otherDate) {
      var timeDiff = Math.abs(date.getTime() - otherDate.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays > 90) {
        responseHandler(false, false, false, CHART_DATE_PERIOD);
        return;
      }
    }

    method(date);
  };
  const renderFilterPanel = () => {
    return (
      <form onSubmit={handleSubmit(handleFilterApply)}>
        <div className={styles['filter-panel-wrapper']}>
          <SelectForm
            name={'sensorID'}
            control={control}
            placeholder={'Название датчика'}
            options={getSensorsOptions()}
          />

          <DatePicker
            dateFormat={'dd.MM.yyyy'}
            label={'С'}
            selected={fromDate}
            onChange={date => dateHandler(date, true)}
          />
          <DatePicker dateFormat={'dd.MM.yyyy'} label={'По'} selected={toDate} onChange={date => dateHandler(date)} />

          <Button className={styles['apply-button']} type="submit">
            Применить
          </Button>
        </div>
      </form>
    );
  };

  const renderChart = () => {
    return (
      <ResponsiveContainer
        width="100%"
        height="100%"
        className={classNames({ [styles['responsive-container']]: !isEmpty(sensorValues) })}
      >
        <LineChart
          data={sensorValues}
          margin={{ top: isEmpty(sensorValues) ? 69 : 140, right: !isEmpty(sensorValues) ? 60 : 0 }}
        >
          <Brush dataKey="createdAt" stroke="#8884d8" y={60}>
            <AreaChart data={sensorValues}>
              <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </Brush>

          <XAxis dataKey="createdAt" height={50} width={50} tick={<StyledXAxis />} />
          <YAxis dataKey="value" style={{ fill: '#fff' }} hide={isEmpty(sensorValues)} />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" />
          <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />
          <Tooltip content={<StyledTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      {renderBackArrow()}
      {renderFilterPanel()}
      {renderChart()}
    </>
  );
};

StyledTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
};

StyledXAxis.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  payload: PropTypes.array,
};
