import { useState, useEffect, useCallback } from 'react';
import { LoginStatusHelper } from 'services/hooks/loginStatusHelper';
import { widgetService } from 'services';
import { ALL_WIDGETS, WidgetTitle } from 'helpers/constants/widgetsConstants';
// import { createBaseApi } from 'services/baseApi';
// import { UserRoleEnum } from 'helpers/constants';

// const baseApi = createBaseApi();
// TODO: добавить данные по id для получения sportEventsAvailability

export function useUserState() {
  const initialUser = JSON.parse(sessionStorage.getItem('user')) || {
    username: '',
    accessToken: '',
    refreshToken: '',
    id: '',
    role: '',
    loging: '',
    permissions: {},
  };

  const [user, setUser] = useState(initialUser);
  const [allowedWidgets, setAllowedWidgets] = useState([]);

  const { changeUser: changeLoggedUser } = LoginStatusHelper.getInstance();

  useEffect(() => {
    window.addEventListener('storage', refreshToken, false);
  }, []);

  const refreshToken = () => {
    const {
      accessToken = '',
      refreshToken = '',
      role,
      username,
      id,
      login,
      permissions,
    } = JSON.parse(sessionStorage.getItem('user'));
    if (accessToken !== user.accessToken)
      changeUser({ accessToken, refreshToken, role, username, id, login, permissions });
  };

  const changeUser = (value = {}) => {
    setUser({ ...user, ...value });
    changeLoggedUser({ ...user, ...value });
  };

  const getAllowedWidgets = useCallback((isMaster = false) => {
    if (isMaster) {
      const allowedWidgets = ALL_WIDGETS.map(widget => {
        const widgetFirstLetter = widget[0];
        const widgetUpperCasedValue = widgetFirstLetter.toUpperCase() + widget.slice(1);
        const widgetName = WidgetTitle[widget];

        return { name: widgetName, value: widgetUpperCasedValue };
      });

      setAllowedWidgets(allowedWidgets);
    } else {
      widgetService
        .getAllowedWidgets()
        .then(response => {
          if (response.status === 200) {
            if (Array.isArray(response?.data)) {
              const allowedWidgets = response.data
                .map(widget => {
                  const widgetFirstLetter = widget[0];
                  const widgetUpperCasedValue = widgetFirstLetter.toUpperCase() + widget.slice(1);
                  const widgetName = WidgetTitle[widgetUpperCasedValue];

                  return { name: widgetName, value: widgetUpperCasedValue };
                })
                .filter(el => Boolean(el.name));

              setAllowedWidgets(allowedWidgets);
            }
          }
        })
        .catch(() => {});
    }
  }, []);

  return {
    user,
    changeUser,

    allowedWidgets,
    setAllowedWidgets,
    getAllowedWidgets,
  };
}
