import { Table } from 'containers/UI/Table';
import { useOperators } from './useOperators';
import PropTypes from 'prop-types';

const Operators = () => {
  const { header, body, isOperatorsLoading, totalOperatorsCount } = useOperators();

  return (
    <Table
      heading={header}
      body={body}
      isLoading={isOperatorsLoading}
      totalEntitiesCount={totalOperatorsCount}
      idKey="email"
      emptyText={'Вы еще не добавили ни одного оператора'}
    />
  );
};

Operators.propTypes = { operators: PropTypes.array, isLoading: PropTypes.bool };

export default Operators;
