import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { Checkbox } from 'containers/UI/Checkbox';
import { SelectForm } from 'containers/UI/Select';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { PermissionTitle } from 'helpers/constants/permissionsConstants';
import { useEffect } from 'react';
import { useDevicesLoad } from 'containers/Hooks/devices/useDevicesLoad';
import { isEmpty } from 'helpers/lodash';

import styles from './ModalOperatorsEdit.module.css';

export const ModalOperatorsEdit = ({ operatorData = {} }) => {
  const {
    closePopup,
    updateOperator,
    fullOperatorData,
    setFullOperatorData,
    getFullOperatorDataById,
    getAllowedWidgets,
    allowedWidgets,
    setAllowedWidgets,
  } = useStateContext();

  const { id, email } = operatorData;

  const { isLoading, devicesOptions, handleDevicesLoad } = useDevicesLoad();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'onChange', defaultValues: operatorData });

  useEffect(() => {
    if (isEmpty(fullOperatorData)) {
      getFullOperatorDataById(operatorData.id);
    }
    reset({
      widgets: fullOperatorData.widgets,
      devices: fullOperatorData.devices,
      filesAvailability: operatorData.permissions.filesAvailability,
      schedulingsAvailability: operatorData.permissions.schedulingsAvailability,
      mobileAppActiveScreenAvailability: operatorData.permissions.mobileAppActiveScreenAvailability,
    });
  }, [fullOperatorData]);

  const save = form => {
    const formData = {
      widgets: form.widgets,
      devices: form.devices,
      permissions: {
        filesAvailability: !!form.filesAvailability,
        schedulingsAvailability: !!form.schedulingsAvailability,
        mobileAppActiveScreenAvailability: !!form.mobileAppActiveScreenAvailability,
      },
    };
    updateOperator({ id, ...formData }, closePopup);
  };

  useEffect(() => {
    if (getAllowedWidgets.length === 0) {
      getAllowedWidgets();
    }

    return () => {
      setAllowedWidgets([]);
      setFullOperatorData({});
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_email_label}>{email}</div>
        <div className={styles.form_fields}>
          <SelectForm
            name="devices"
            control={control}
            placeholder={'Устройство'}
            label={'Доступные устройства'}
            options={devicesOptions}
            searchable
            isMulti
            isInfinityLoad
            onLoad={handleDevicesLoad}
          />

          <SelectForm
            name="widgets"
            control={control}
            placeholder={'Выберите виджеты'}
            label={'Доступные виджеты'}
            options={allowedWidgets}
            searchable
            isMulti
          />

          <div className={styles.form_checkboxes}>
            {Object.keys(PermissionTitle).map(key => {
              return <Checkbox key={key} name={key} label={PermissionTitle[key]} control={control} />;
            })}
          </div>
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} disabled={!isValid || isLoading} type="submit">
            Сохранить
          </Button>
          <Button
            className={styles.form_button}
            variant={ButtonVariantEnum.SOLID_ACCENT}
            disabled={isLoading}
            onClick={closePopup}
          >
            Отмена
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalOperatorsEdit.propTypes = {
  operatorData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    widgets: PropTypes.array,
    devices: PropTypes.array,
    permissions: PropTypes.shape({
      filesAvailability: PropTypes.bool,
      schedulingsAvailability: PropTypes.bool,
      mobileAppActiveScreenAvailability: PropTypes.bool,
    }),
  }),
};
