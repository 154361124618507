import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useStateContext } from 'store';
import { isEmpty } from 'helpers/lodash';
import { OS_ENUM } from '../../Constants/common';
import { hmsToSeconds } from 'helpers/time/hmsToSeconds';
import { useDevicesLoad } from 'containers/Hooks/devices/useDevicesLoad';
import { SelectForm } from 'containers/UI/Select';
import { Checkbox } from 'containers/UI/Checkbox';
import { TimePickerForm } from 'containers/UI/TimePicker';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './ModalTerminalUpdates.module.css';
import { Input, InputTypeEnum } from 'containers/UI/Input';

export const ModalTerminalUpdate = ({ terminalUpdate = {} }) => {
  const [isValidTime, setIsValidTime] = useState(true);
  const {
    closePopup,
    addTerminalUpdate,
    terminalReleasesForTerminalUpdates,
    getTerminalReleasesForTerminalUpdates,
    updateTerminalUpdateStatus,
  } = useStateContext();
  const addMode = isEmpty(terminalUpdate);
  const editMode = !addMode;

  const { handleSubmit, control, getValues, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: terminalUpdate || { os: OS_ENUM[0] },
  });
  const status = watch('status');

  const componentDidMount = function () {
    useEffect(() => {
      getTerminalReleasesForTerminalUpdates();
    }, []);
  };
  componentDidMount();

  const updateFormValues = function () {
    useEffect(() => {
      reset({ status: terminalUpdate.state === 'active', start: '', end: '' });
    }, [terminalReleasesForTerminalUpdates]);
  };
  updateFormValues();

  const { devicesOptions, handleDevicesLoad } = useDevicesLoad();

  const validateTimeFields = function () {
    useEffect(() => {
      if (watch('end')) {
        if (watch('start')) {
          const { start, end } = getValues();
          const startSec = hmsToSeconds(start);
          const endSec = hmsToSeconds(end);

          setIsValidTime(startSec < endSec && startSec !== endSec);
        }
      }
    }, [watch('start'), watch('end')]);
  };
  validateTimeFields();

  const isFormValid = () => {
    if (addMode) {
      return isValidTime && watch('releaseId') && watch('devices') && watch('end');
    }

    if (editMode) {
      return true;
    }
  };

  const save = form => {
    if (addMode) {
      const newTerminalUpdate = {
        releaseId: form.releaseId[0],
        timeFrom: form.start,
        timeTo: form.end,
        devices: form.devices,
        os: form.os,
        state: form.status ? 'active' : 'inactive',
      };

      addTerminalUpdate(newTerminalUpdate, closePopup);
    }

    if (editMode) {
      updateTerminalUpdateStatus(terminalUpdate.id, form.status, closePopup);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          {addMode && (
            <>
              <Controller
                control={control}
                name="os"
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={'Операционная система'}
                    value={value}
                    type={InputTypeEnum.Radio}
                    // defaultRadioVariant={OS_ENUM[0]}
                    handleInput={onChange}
                    radioVariants={OS_ENUM.map(value => ({ name: value, value }))}
                  />
                )}
              />
              <SelectForm
                name="releaseId"
                control={control}
                placeholder={'Релиз'}
                label={'Выберите релиз из списка'}
                options={terminalReleasesForTerminalUpdates}
                searchable
                isMulti
                required
              />

              <div className={styles.form_time_fields}>
                <TimePickerForm
                  name="start"
                  control={control}
                  valid={isValidTime}
                  label={'Время начала периода обновления'}
                  required
                />

                <TimePickerForm
                  name="end"
                  control={control}
                  valid={isValidTime}
                  label={'Время завершения периода обновления'}
                  required
                />
              </div>
            </>
          )}
          <div className={styles.form_status_field}>
            <span>Статус</span>
            <Checkbox key="status" label={status ? 'Active' : 'Inactive'} name="status" control={control} />
          </div>

          {addMode && (
            <SelectForm
              name="devices"
              control={control}
              placeholder={'Устройства'}
              label={'Доступные устройства'}
              options={devicesOptions}
              searchable
              isMulti
              required
              isInfinityLoad
              onLoad={handleDevicesLoad}
            />
          )}
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={!isFormValid()}>
            {addMode ? 'Создать' : 'Изменить'}
          </Button>
          <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
            Закрыть
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalTerminalUpdate.propTypes = {
  terminalUpdate: PropTypes.object,
};
