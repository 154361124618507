import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { FUEL_TYPES, WIDGETS_DEFAULT, WIDGETS_FORMS, WidgetParamTypeEnum } from '../helpers';
import { Input } from 'containers/UI/Input';
import { Button } from 'containers/UI/Button';
import { Select, SelectVariantEnum } from 'containers/UI/Select';
import { ReactComponent as Add } from 'icons/add.svg';

import styles from '../Desktops.module.css';

export const DesktopFormFuel = ({ fieldKey = '', label = '' }) => {
  const { indexSelect, selectWidgetIndex, control, register, watch } = useFormContext();
  const name = `screens.${indexSelect}.widgets.${selectWidgetIndex}.${fieldKey}`;
  const { append, remove } = useFieldArray({ control, name });
  const fields = watch(name);

  const [selected, setSelected] = useState(-1);

  const typesList = FUEL_TYPES.filter(t => !fields.find(f => t === f.type)).map(value => ({ name: value, value }));
  return (
    <>
      <div className={styles['fuel-field']}>
        <Select
          variant={SelectVariantEnum.FLAT}
          label={label}
          options={fields.map(({ type }, index) => ({ name: type, value: index }))}
          selected={selected}
          onChange={setSelected}
        />
        <Select
          variant={SelectVariantEnum.NOT_BORDERED}
          placeholder={<Add />}
          options={typesList}
          disabled={!typesList.length}
          onChange={type => {
            setSelected(fields.length);
            append({ ...WIDGETS_DEFAULT.FuelType, type });
          }}
        />
      </div>

      {!!(selected + 1) &&
        WIDGETS_FORMS({}).FuelType.map(({ key, label, type, list, ...other }) => {
          if (type === WidgetParamTypeEnum.SELECT) {
            return (
              <Controller
                key={`${name}.${selected}.${key}`}
                control={control}
                name={`${name}.${selected}.${key}`}
                render={({ field: { onChange, value } }) => (
                  <Select
                    wrapperClassName={styles['params-field']}
                    variant={SelectVariantEnum.FLAT}
                    label={label}
                    placeholder={label}
                    options={list}
                    selected={value}
                    onChange={onChange}
                  />
                )}
              />
            );
          }
          return (
            <Input
              key={`${name}.${selected}.${key}`}
              className={styles['params-field']}
              label={label}
              placeholder={label}
              {...other}
              {...register(`${name}.${selected}.${key}`)}
            />
          );
        })}

      {!!(selected + 1) && (
        <Button
          className={styles['delete-fuel-type']}
          onClick={() => {
            remove(selected);
            setSelected(-1);
          }}
        >
          Удалить
        </Button>
      )}
    </>
  );
};

DesktopFormFuel.propTypes = {
  fieldKey: PropTypes.string,
  label: PropTypes.string,
};
