import { showErrorNotify, showSuccessNotify } from 'helpers/showNotify';
import {
  CLIENT_CANNOT_BE_DELETED,
  CLIENT_NOT_FOUND,
  CLIENTLICENSE_EXPIRED,
  CLIENTRESOURCE_NOT_FOUND,
  DESKTOP_SHOULD_BE_CHOSEN,
  EMAIL_NOT_UNIQUE,
  FILE_NOT_FOUND,
  LICENSENUMBER_CANNOT_BE_CHANGED,
  LICENSENUMBER_NOT_UNIQUE,
  NAME_NOT_UNIQUE,
  PLAYLIST_NOT_FOUND,
  PLAYLISTSGROUP_CANNOT_BE_DELETED,
  PLAYLISTSGROUP_NOT_FOUND,
  PRESENTATION_NOT_FOUND,
  PRESENTATIONSGROUP_NOT_FOUND,
  RELEASESCHEDULE_NOT_UNIQUE,
  SCHEDULINGSGROUP_NOT_FOUND,
  UNAUTHORIZED,
  USER_BLOCKED,
  USERNAME_OR_PASSWORD_INCORRECT,
  VALIDATION_ERROR,
  CLIENTSGROUP_CANNOT_BE_DELETED,
  CHART_DATE_PERIOD,
  MAXTOTALFILESSIZE_EXCEEDED,
} from 'helpers/constants/responseCodes';

/**
 *
 * @param success
 * @param successMessage
 * @param onModalClose
 * @param errorCode
 */
export const responseHandler = (success, successMessage, onModalClose = () => {}, errorCode) => {
  if (success) {
    onModalClose && onModalClose();
    showSuccessNotify(successMessage);
  } else {
    onModalClose && onModalClose();
    showErrorNotify(getErrorMessage(errorCode));
  }
};

const getErrorMessage = errorCode => {
  switch (errorCode) {
    case NAME_NOT_UNIQUE:
      return 'Данное имя не является уникальным. Пожалуйста используйте другое';
    case SCHEDULINGSGROUP_NOT_FOUND:
      return 'Группы расписаний с таким идентификатором не существует';
    case PRESENTATIONSGROUP_NOT_FOUND:
      return 'Группы презентаций с таким идентификатором не существует';
    case PRESENTATION_NOT_FOUND:
      return 'Презентации с данным идентификатором не существует';
    case PLAYLISTSGROUP_NOT_FOUND:
      return 'Группы плейлистов с таким идентификатором не существует';
    case PLAYLIST_NOT_FOUND:
      return 'Плейлист не найден';
    case USERNAME_OR_PASSWORD_INCORRECT:
      return 'Имя пользователя или пароль были введены некорректно. Пожалуйста, введите другие значения';
    case USER_BLOCKED:
      return 'Данная учётная запись заблокирована';
    case FILE_NOT_FOUND:
      return 'Файл не найден';
    case PLAYLISTSGROUP_CANNOT_BE_DELETED:
      return 'Данная группа плейлистов не может быть удалена';
    case RELEASESCHEDULE_NOT_UNIQUE:
      return 'На данный релиз уже существует задание на обновление';
    case EMAIL_NOT_UNIQUE:
      return 'Пользователь с указанным email уже существует';
    case CLIENT_NOT_FOUND:
      return 'Организация с указанным номером лицензии не существует';
    case LICENSENUMBER_NOT_UNIQUE:
      return 'Пользователь с указанным номером лицензии уже существует';
    case CLIENTRESOURCE_NOT_FOUND:
      return 'Для данной организации не определен ресурс';
    case LICENSENUMBER_CANNOT_BE_CHANGED:
      return 'Номер лицензии не может быть изменён, так как с организацией связан администратор';
    case CLIENTLICENSE_EXPIRED:
      return 'Срок действия лицензии данной организации истёк';
    case UNAUTHORIZED:
      return 'Ошибка авторизации';
    case VALIDATION_ERROR:
      return 'Ошибка валидации';
    case CLIENT_CANNOT_BE_DELETED:
      return 'Невозможно удалить организацию';
    case DESKTOP_SHOULD_BE_CHOSEN:
      return 'Сначала выберете рабочий стол';
    case CLIENTSGROUP_CANNOT_BE_DELETED:
      return 'Невозможно удалить группу, так как в ней есть организации';
    case CHART_DATE_PERIOD:
      return 'Период не может быть больше 90 дней';
    case MAXTOTALFILESSIZE_EXCEEDED:
      return 'Превышен общий объём загружаемых файлов для организации';
    default:
      return 'Неизвестная ошибка. Пожалуйста, обратитесь в поддержку';
  }
};
